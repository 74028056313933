export const SCHOOL_LIST = [
    {
    "id": 48096,
    "label": "A B Paterson College",
    "franchise": "GCNR"
    },
    {
    "id": 46801,
    "label": "Abercorn State School",
    "franchise": "CQ"
    },
    {
    "id": 47089,
    "label": "Abergowrie State School",
    "franchise": "CQ"
    },
    {
    "id": 48059,
    "label": "Aboriginal and Islander Independent Community School",
    "franchise": "SEQ"
    },
    {
    "id": 46412,
    "label": "Acacia Ridge State School",
    "franchise": "SEQ"
    },
    {
    "id": "43780",
    "label": "Aetaomah School",
    "franchise": "GCNR"
    },
    {
    "id": 47391,
    "label": "Agnes Water State School",
    "franchise": "CQ"
    },
    {
    "id": 46805,
    "label": "Airville State School",
    "franchise": "CQ"
    },
    {
    "id": 46899,
    "label": "Aitkenvale State School",
    "franchise": "CQ"
    },
    {
    "id": 47534,
    "label": "Albany Creek State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46546,
    "label": "Albany Creek State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47310,
    "label": "Albany Hills State School",
    "franchise": "Brisbane"
    },
    {
    "id": 40769,
    "label": "Albert Park Flexible Learning Centre",
    "franchise": "Brisbane"
    },
    {
    "id": "42339",
    "label": "Albert Park Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46424,
    "label": "Albert State School",
    "franchise": "CQ"
    },
    {
    "id": 47419,
    "label": "Aldridge State High School",
    "franchise": "CQ"
    },
    {
    "id": 47373,
    "label": "Alexandra Bay State School",
    "franchise": "CQ"
    },
    {
    "id": 47553,
    "label": "Alexandra Hills State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46686,
    "label": "Alexandra Hills State School",
    "franchise": "SEQ"
    },
    {
    "id": 47289,
    "label": "Algester State School",
    "franchise": "SEQ"
    },
    {
    "id": 47882,
    "label": "All Hallows' School",
    "franchise": "Brisbane"
    },
    {
    "id": 48002,
    "label": "All Saints Anglican School (Merrimac)",
    "franchise": "GCNR"
    },
    {
    "id": 47925,
    "label": "All Saints Primary School (Albany Creek)",
    "franchise": "Brisbane"
    },
    {
    "id": 47743,
    "label": "All Saints' School (Boonah)",
    "franchise": "SEQ"
    },
    {
    "id": 48001,
    "label": "All Souls St Gabriels School",
    "franchise": "CQ"
    },
    {
    "id": 46516,
    "label": "Allenstown State School",
    "franchise": "CQ"
    },
    {
    "id": 46886,
    "label": "Alligator Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46396,
    "label": "Allora P-10 State School",
    "franchise": "SEQ"
    },
    {
    "id": 46698,
    "label": "Alloway State School",
    "franchise": "CQ"
    },
    {
    "id": 46923,
    "label": "Aloomba State School",
    "franchise": "CQ"
    },
    {
    "id": 46757,
    "label": "Alpha State School",
    "franchise": "CQ"
    },
    {
    "id": "42405",
    "label": "Alstonville High School",
    "franchise": "GCNR"
    },
    {
    "id": "42138",
    "label": "Alstonville Public School",
    "franchise": "GCNR"
    },
    {
    "id": 51521,
    "label": "Alta-1 College",
    "franchise": "Brisbane"
    },
    {
    "id": 47237,
    "label": "Amamoor State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46485,
    "label": "Amberley District State School",
    "franchise": "SEQ"
    },
    {
    "id": 47134,
    "label": "Ambrose State School",
    "franchise": "CQ"
    },
    {
    "id": 47720,
    "label": "Ambrose Treacy College",
    "franchise": "Brisbane"
    },
    {
    "id": 47206,
    "label": "Amiens State School",
    "franchise": "SEQ"
    },
    {
    "id": 46730,
    "label": "Anakie State School",
    "franchise": "CQ"
    },
    {
    "id": 47189,
    "label": "Andergrove State School",
    "franchise": "CQ"
    },
    {
    "id": 52446,
    "label": "Angelorum College",
    "franchise": "Brisbane"
    },
    {
    "id": 47996,
    "label": "Anglican Church Grammar School",
    "franchise": "SEQ"
    },
    {
    "id": 48048,
    "label": "Annandale Christian College",
    "franchise": "CQ"
    },
    {
    "id": 46702,
    "label": "Annandale State School",
    "franchise": "CQ"
    },
    {
    "id": 47095,
    "label": "Applethorpe State School",
    "franchise": "SEQ"
    },
    {
    "id": 47860,
    "label": "Aquinas College",
    "franchise": "GCNR"
    },
    {
    "id": 46627,
    "label": "Aramac State School",
    "franchise": "CQ"
    },
    {
    "id": 47086,
    "label": "Aratula State School",
    "franchise": "SEQ"
    },
    {
    "id": 52493,
    "label": "Arcadia College",
    "franchise": "GCNR"
    },
    {
    "id": 46905,
    "label": "Arcadia Valley State School",
    "franchise": "CQ"
    },
    {
    "id": 48115,
    "label": "Arethusa College",
    "franchise": "Brisbane"
    },
    {
    "id": 47404,
    "label": "Arundel State School",
    "franchise": "GCNR"
    },
    {
    "id": 46615,
    "label": "Ascot State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46544,
    "label": "Ashgrove State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47294,
    "label": "Ashmore State School",
    "franchise": "GCNR"
    },
    {
    "id": 46754,
    "label": "Ashwell State School",
    "franchise": "SEQ"
    },
    {
    "id": 46985,
    "label": "Aspley East State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47597,
    "label": "Aspley Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47464,
    "label": "Aspley State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46813,
    "label": "Aspley State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47859,
    "label": "Assisi Catholic College",
    "franchise": "GCNR"
    },
    {
    "id": 47894,
    "label": "Assumption College",
    "franchise": "SEQ"
    },
    {
    "id": 47445,
    "label": "Atherton State High School",
    "franchise": "CQ"
    },
    {
    "id": 46895,
    "label": "Atherton State School",
    "franchise": "CQ"
    },
    {
    "id": 46655,
    "label": "Augathella State School",
    "franchise": "NE"
    },
    {
    "id": 50254,
    "label": "Augusta State School",
    "franchise": "SEQ"
    },
    {
    "id": 46598,
    "label": "Aurukun State School",
    "franchise": "CQ"
    },
    {
    "id": 47952,
    "label": "Australian Christian College - Moreton",
    "franchise": "Brisbane"
    },
    {
    "id": 52822,
    "label": "Australian Industry Trade College - Ipswich",
    "franchise": "SEQ"
    },
    {
    "id": 52243,
    "label": "Australian Industry Trade College - Redlands",
    "franchise": "SEQ"
    },
    {
    "id": 52792,
    "label": "Australian Industry Trade College - Toowoomba",
    "franchise": "SEQ"
    },
    {
    "id": 40816,
    "label": "Australian Industry Trade College (Robina)",
    "franchise": "GCNR"
    },
    {
    "id": 47989,
    "label": "Australian International Islamic College (Carrara)",
    "franchise": "GCNR"
    },
    {
    "id": 52454,
    "label": "Australian Technology and Agricultural College",
    "franchise": "SEQ"
    },
    {
    "id": 40797,
    "label": "Australian Trade College North Brisbane",
    "franchise": "Brisbane"
    },
    {
    "id": 40402,
    "label": "Autism Queensland Education and Therapy Centre",
    "franchise": "SEQ"
    },
    {
    "id": 47466,
    "label": "Aviation High",
    "franchise": "Brisbane"
    },
    {
    "id": 47316,
    "label": "Avoca State School",
    "franchise": "CQ"
    },
    {
    "id": 46788,
    "label": "Avondale State School",
    "franchise": "CQ"
    },
    {
    "id": 47413,
    "label": "Ayr State High School",
    "franchise": "CQ"
    },
    {
    "id": 46709,
    "label": "Ayr State School",
    "franchise": "CQ"
    },
    {
    "id": 47146,
    "label": "Babinda State School",
    "franchise": "CQ"
    },
    {
    "id": 46630,
    "label": "Back Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": 46937,
    "label": "Bajool State School",
    "franchise": "CQ"
    },
    {
    "id": 47228,
    "label": "Balaclava State School",
    "franchise": "CQ"
    },
    {
    "id": 46397,
    "label": "Bald Hills State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47044,
    "label": "Ballandean State School",
    "franchise": "SEQ"
    },
    {
    "id": "42383",
    "label": "Ballina Coast High School",
    "franchise": "GCNR"
    },
    {
    "id": "42139",
    "label": "Ballina Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47437,
    "label": "Balmoral State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46761,
    "label": "Banana State School",
    "franchise": "CQ"
    },
    {
    "id": "42140",
    "label": "Bangalow Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47396,
    "label": "Banksia Beach State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42387",
    "label": "Banora Point High School",
    "franchise": "GCNR"
    },
    {
    "id": "42141",
    "label": "Banora Point Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47200,
    "label": "Baralaba State School",
    "franchise": "CQ"
    },
    {
    "id": 46770,
    "label": "Barcaldine Prep-12 State School",
    "franchise": "CQ"
    },
    {
    "id": 47001,
    "label": "Bardon State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46856,
    "label": "Bargara State School",
    "franchise": "CQ"
    },
    {
    "id": 52608,
    "label": "Baringa State Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 52886,
    "label": "Baringa State Secondary College",
    "franchise": "Brisbane"
    },
    {
    "id": "42142",
    "label": "Barkers Vale Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46889,
    "label": "Barkly Highway State School",
    "franchise": "CQ"
    },
    {
    "id": 40687,
    "label": "Barrett Adolescent Centre Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47244,
    "label": "Bartle Frere State School",
    "franchise": "CQ"
    },
    {
    "id": "42144",
    "label": "Baryulgil Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46674,
    "label": "Bauhinia State School",
    "franchise": "CQ"
    },
    {
    "id": 46969,
    "label": "Bauple State School",
    "franchise": "CQ"
    },
    {
    "id": 47267,
    "label": "Bay View State School",
    "franchise": "SEQ"
    },
    {
    "id": 48052,
    "label": "Bayside Christian College Hervey Bay",
    "franchise": "CQ"
    },
    {
    "id": 47371,
    "label": "Beachmere State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47220,
    "label": "Beaconsfield State School",
    "franchise": "CQ"
    },
    {
    "id": 47468,
    "label": "Beaudesert State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46518,
    "label": "Beaudesert State School",
    "franchise": "SEQ"
    },
    {
    "id": 46993,
    "label": "Bedourie State School",
    "franchise": "NE"
    },
    {
    "id": 46846,
    "label": "Beechmont State School",
    "franchise": "GCNR"
    },
    {
    "id": 47609,
    "label": "Beenleigh Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47469,
    "label": "Beenleigh State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46398,
    "label": "Beenleigh State School",
    "franchise": "SEQ"
    },
    {
    "id": 47198,
    "label": "Beerburrum State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47494,
    "label": "Beerwah State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46786,
    "label": "Beerwah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46545,
    "label": "Begonia State School",
    "franchise": "NE"
    },
    {
    "id": 46752,
    "label": "Belgian Gardens State School",
    "franchise": "CQ"
    },
    {
    "id": 46891,
    "label": "Bell State School",
    "franchise": "SEQ"
    },
    {
    "id": 52458,
    "label": "Bellbird Park State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 47261,
    "label": "Bellenden Ker State School",
    "franchise": "CQ"
    },
    {
    "id": 47338,
    "label": "Bellevue Park State School",
    "franchise": "GCNR"
    },
    {
    "id": 52246,
    "label": "Bellmere State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46572,
    "label": "Belmont State School",
    "franchise": "SEQ"
    },
    {
    "id": 46678,
    "label": "Benaraby State School",
    "franchise": "CQ"
    },
    {
    "id": 47208,
    "label": "Benarkin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47529,
    "label": "Benowa State High School",
    "franchise": "GCNR"
    },
    {
    "id": 46735,
    "label": "Benowa State School",
    "franchise": "GCNR"
    },
    {
    "id": 46710,
    "label": "Bentley Park College",
    "franchise": "CQ"
    },
    {
    "id": 47290,
    "label": "Berrinba East State School",
    "franchise": "SEQ"
    },
    {
    "id": 47180,
    "label": "Berserker Street State School",
    "franchise": "CQ"
    },
    {
    "id": 48024,
    "label": "Bethania Lutheran Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 48072,
    "label": "Bethany Lutheran Primary School",
    "franchise": "SEQ"
    },
    {
    "id": "42149",
    "label": "Bexhill Public School",
    "franchise": "GCNR"
    },
    {
    "id": "43808",
    "label": "Bhaktivedanta Swami Gurukula School",
    "franchise": "GCNR"
    },
    {
    "id": "43736",
    "label": "Biala Special School",
    "franchise": "GCNR"
    },
    {
    "id": 46982,
    "label": "Biboohra State School",
    "franchise": "CQ"
    },
    {
    "id": 47215,
    "label": "Biddeston State School",
    "franchise": "SEQ"
    },
    {
    "id": 46838,
    "label": "Biggenden State School",
    "franchise": "CQ"
    },
    {
    "id": 46449,
    "label": "Biggera Waters State School",
    "franchise": "GCNR"
    },
    {
    "id": "42150",
    "label": "Bilambil Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47470,
    "label": "Biloela State High School",
    "franchise": "CQ"
    },
    {
    "id": 47273,
    "label": "Biloela State School",
    "franchise": "CQ"
    },
    {
    "id": 47129,
    "label": "Binjour Plateau State School",
    "franchise": "CQ"
    },
    {
    "id": 52666,
    "label": "Birali Steiner School",
    "franchise": "Brisbane"
    },
    {
    "id": 47226,
    "label": "Birdsville State School",
    "franchise": "NE"
    },
    {
    "id": 47328,
    "label": "Birkdale South State School",
    "franchise": "SEQ"
    },
    {
    "id": 47179,
    "label": "Birkdale State School",
    "franchise": "SEQ"
    },
    {
    "id": 48028,
    "label": "Blackall Range Independent School",
    "franchise": "Brisbane"
    },
    {
    "id": 46618,
    "label": "Blackall State School",
    "franchise": "CQ"
    },
    {
    "id": 46873,
    "label": "Blackbutt State School",
    "franchise": "SEQ"
    },
    {
    "id": 48091,
    "label": "Blackheath and Thornburgh College",
    "franchise": "CQ"
    },
    {
    "id": 47309,
    "label": "Blackwater North State School",
    "franchise": "CQ"
    },
    {
    "id": 47515,
    "label": "Blackwater State High School",
    "franchise": "CQ"
    },
    {
    "id": 46681,
    "label": "Blackwater State School",
    "franchise": "CQ"
    },
    {
    "id": 47186,
    "label": "Blair State School",
    "franchise": "SEQ"
    },
    {
    "id": "42152",
    "label": "Blakebrook Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46629,
    "label": "Blenheim State School",
    "franchise": "SEQ"
    },
    {
    "id": 46961,
    "label": "Bli Bli State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47050,
    "label": "Bloomfield River State School",
    "franchise": "CQ"
    },
    {
    "id": 46976,
    "label": "Bloomsbury State School",
    "franchise": "CQ"
    },
    {
    "id": "43778",
    "label": "Blue Hills College",
    "franchise": "GCNR"
    },
    {
    "id": 46814,
    "label": "Bluewater State School",
    "franchise": "CQ"
    },
    {
    "id": 46646,
    "label": "Bluff State School",
    "franchise": "CQ"
    },
    {
    "id": "42362",
    "label": "Bogangar Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47098,
    "label": "Bohlevale State School",
    "franchise": "CQ"
    },
    {
    "id": 46697,
    "label": "Bollon State School",
    "franchise": "NE"
    },
    {
    "id": "42155",
    "label": "Bonalbo Central School",
    "franchise": "GCNR"
    },
    {
    "id": 47490,
    "label": "Boonah State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46638,
    "label": "Boonah State School",
    "franchise": "SEQ"
    },
    {
    "id": 47199,
    "label": "Boondall State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47178,
    "label": "Booyal Central State School",
    "franchise": "CQ"
    },
    {
    "id": 52443,
    "label": "Border Rivers Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 47389,
    "label": "Boronia Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 46935,
    "label": "Bororen State School",
    "franchise": "CQ"
    },
    {
    "id": 46441,
    "label": "Bouldercombe State School",
    "franchise": "CQ"
    },
    {
    "id": 46804,
    "label": "Boulia State School",
    "franchise": "NE"
    },
    {
    "id": 47641,
    "label": "Bounty Boulevard State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47458,
    "label": "Bowen State High School",
    "franchise": "CQ"
    },
    {
    "id": 46400,
    "label": "Bowen State School",
    "franchise": "CQ"
    },
    {
    "id": 46906,
    "label": "Bowenville State School",
    "franchise": "SEQ"
    },
    {
    "id": 47058,
    "label": "Boyne Island State School",
    "franchise": "CQ"
    },
    {
    "id": 47145,
    "label": "Boynewood State School",
    "franchise": "CQ"
    },
    {
    "id": 47501,
    "label": "Bracken Ridge State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46885,
    "label": "Bracken Ridge State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46722,
    "label": "Brandon State School",
    "franchise": "CQ"
    },
    {
    "id": 47009,
    "label": "Branyan Road State School",
    "franchise": "CQ"
    },
    {
    "id": 46787,
    "label": "Brassall State School",
    "franchise": "SEQ"
    },
    {
    "id": 47551,
    "label": "Bray Park State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46642,
    "label": "Bray Park State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47444,
    "label": "Bremer State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47558,
    "label": "Bribie Island State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46774,
    "label": "Bribie Island State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47032,
    "label": "Brigalow State School",
    "franchise": "NE"
    },
    {
    "id": 47218,
    "label": "Brighton State School",
    "franchise": "Brisbane"
    },
    {
    "id": 50484,
    "label": "Brightwater State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47867,
    "label": "Brigidine College",
    "franchise": "Brisbane"
    },
    {
    "id": 47957,
    "label": "Brisbane Adventist College",
    "franchise": "SEQ"
    },
    {
    "id": 47489,
    "label": "Brisbane Bayside State College",
    "franchise": "SEQ"
    },
    {
    "id": 48011,
    "label": "Brisbane Boys' College",
    "franchise": "Brisbane"
    },
    {
    "id": 46557,
    "label": "Brisbane Central State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48105,
    "label": "Brisbane Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 48034,
    "label": "Brisbane Girls Grammar School",
    "franchise": "Brisbane"
    },
    {
    "id": 48033,
    "label": "Brisbane Grammar School (Brisbane)",
    "franchise": "Brisbane"
    },
    {
    "id": 48030,
    "label": "Brisbane Independent School",
    "franchise": "Brisbane"
    },
    {
    "id": 48085,
    "label": "Brisbane Montessori School",
    "franchise": "Brisbane"
    },
    {
    "id": 47625,
    "label": "Brisbane School of Distance Education",
    "franchise": "SEQ"
    },
    {
    "id": 52849,
    "label": "Brisbane South State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 47408,
    "label": "Brisbane State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47572,
    "label": "Brisbane Youth Education and Training Centre",
    "franchise": "SEQ"
    },
    {
    "id": 47122,
    "label": "Broadbeach State School",
    "franchise": "GCNR"
    },
    {
    "id": "42160",
    "label": "Broadwater Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47012,
    "label": "Broadwater State School",
    "franchise": "SEQ"
    },
    {
    "id": 46403,
    "label": "Brookfield State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46626,
    "label": "Brookstead State School",
    "franchise": "SEQ"
    },
    {
    "id": 46999,
    "label": "Brooweena State School",
    "franchise": "CQ"
    },
    {
    "id": 47543,
    "label": "Browns Plains State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47335,
    "label": "Browns Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": "42161",
    "label": "Brunswick Heads Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47349,
    "label": "Bucasia State School",
    "franchise": "CQ"
    },
    {
    "id": 47306,
    "label": "Buddina State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46569,
    "label": "Buderim Mountain State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47028,
    "label": "Builyan State School",
    "franchise": "CQ"
    },
    {
    "id": 46404,
    "label": "Bulimba State School",
    "franchise": "SEQ"
    },
    {
    "id": 46967,
    "label": "Bullyard State School",
    "franchise": "CQ"
    },
    {
    "id": 46405,
    "label": "Bundaberg Central State School",
    "franchise": "CQ"
    },
    {
    "id": 47943,
    "label": "Bundaberg Christian College",
    "franchise": "CQ"
    },
    {
    "id": 46745,
    "label": "Bundaberg East State School",
    "franchise": "CQ"
    },
    {
    "id": 47420,
    "label": "Bundaberg North State High School",
    "franchise": "CQ"
    },
    {
    "id": 46521,
    "label": "Bundaberg North State School",
    "franchise": "CQ"
    },
    {
    "id": 46827,
    "label": "Bundaberg South State School",
    "franchise": "CQ"
    },
    {
    "id": 47596,
    "label": "Bundaberg Special School",
    "franchise": "CQ"
    },
    {
    "id": 47409,
    "label": "Bundaberg State High School",
    "franchise": "CQ"
    },
    {
    "id": 47275,
    "label": "Bundaberg West State School",
    "franchise": "CQ"
    },
    {
    "id": 46406,
    "label": "Bundamba State School",
    "franchise": "SEQ"
    },
    {
    "id": 47511,
    "label": "Bundamba State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 47164,
    "label": "Bungunya State School",
    "franchise": "SEQ"
    },
    {
    "id": 46915,
    "label": "Bunker's Hill State School",
    "franchise": "SEQ"
    },
    {
    "id": 46933,
    "label": "Buranda State School",
    "franchise": "SEQ"
    },
    {
    "id": 47908,
    "label": "Burdekin Catholic High School",
    "franchise": "CQ"
    },
    {
    "id": 48103,
    "label": "Burdekin Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47582,
    "label": "Burdekin School",
    "franchise": "CQ"
    },
    {
    "id": 46769,
    "label": "Burketown State School",
    "franchise": "CQ"
    },
    {
    "id": 47187,
    "label": "Burleigh Heads State School",
    "franchise": "GCNR"
    },
    {
    "id": 46632,
    "label": "Burnett Heads State School",
    "franchise": "CQ"
    },
    {
    "id": 47474,
    "label": "Burnett State College",
    "franchise": "CQ"
    },
    {
    "id": 40778,
    "label": "Burnett Youth Learning Centre",
    "franchise": "CQ"
    },
    {
    "id": 47524,
    "label": "Burnside State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47296,
    "label": "Burnside State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47633,
    "label": "Burpengary Meadows State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46806,
    "label": "Burpengary State School",
    "franchise": "Brisbane"
    },
    {
    "id": 51495,
    "label": "Burpengary State Secondary College",
    "franchise": "Brisbane"
    },
    {
    "id": "42164",
    "label": "Burringbar Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47375,
    "label": "Burrowes State School",
    "franchise": "SEQ"
    },
    {
    "id": 47139,
    "label": "Butchers Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46670,
    "label": "Bwgcolman Community School",
    "franchise": "CQ"
    },
    {
    "id": 47105,
    "label": "Byfield State School",
    "franchise": "CQ"
    },
    {
    "id": 47003,
    "label": "Bymount East State School",
    "franchise": "NE"
    },
    {
    "id": "42404",
    "label": "Byron Bay High School",
    "franchise": "GCNR"
    },
    {
    "id": "42166",
    "label": "Byron Bay Public School",
    "franchise": "GCNR"
    },
    {
    "id": "43833",
    "label": "Byron Community Primary School",
    "franchise": "GCNR"
    },
    {
    "id": "42167",
    "label": "Cabbage Tree Island Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47318,
    "label": "Caboolture East State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47953,
    "label": "Caboolture Montessori School",
    "franchise": "Brisbane"
    },
    {
    "id": 47607,
    "label": "Caboolture Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47459,
    "label": "Caboolture State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46663,
    "label": "Caboolture State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48021,
    "label": "Cairns Adventist College",
    "franchise": "CQ"
    },
    {
    "id": 47969,
    "label": "Cairns Hinterland Steiner School",
    "franchise": "CQ"
    },
    {
    "id": 47622,
    "label": "Cairns School of Distance Education",
    "franchise": "CQ"
    },
    {
    "id": 47410,
    "label": "Cairns State High School",
    "franchise": "CQ"
    },
    {
    "id": 52461,
    "label": "Cairns State Special School",
    "franchise": "CQ"
    },
    {
    "id": 47171,
    "label": "Cairns West State School",
    "franchise": "CQ"
    },
    {
    "id": 47231,
    "label": "Calamvale Community College",
    "franchise": "SEQ"
    },
    {
    "id": 47611,
    "label": "Calamvale Special School",
    "franchise": "SEQ"
    },
    {
    "id": "42366",
    "label": "Caldera School",
    "franchise": "GCNR"
    },
    {
    "id": 47182,
    "label": "Calen District State College",
    "franchise": "CQ"
    },
    {
    "id": 52801,
    "label": "Calliope State High School",
    "franchise": "CQ"
    },
    {
    "id": 46407,
    "label": "Calliope State School",
    "franchise": "CQ"
    },
    {
    "id": 48057,
    "label": "Caloundra Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47985,
    "label": "Caloundra City Private School",
    "franchise": "Brisbane"
    },
    {
    "id": 47499,
    "label": "Caloundra State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46917,
    "label": "Caloundra State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48043,
    "label": "Calvary Christian College (Carbrook/Springwood)",
    "franchise": "SEQ"
    },
    {
    "id": 48106,
    "label": "Calvary Christian College (Mount Louisa)",
    "franchise": "CQ"
    },
    {
    "id": 46551,
    "label": "Cambooya State School",
    "franchise": "SEQ"
    },
    {
    "id": 46737,
    "label": "Cameron Downs State School",
    "franchise": "CQ"
    },
    {
    "id": 46650,
    "label": "Camira State School",
    "franchise": "SEQ"
    },
    {
    "id": 46863,
    "label": "Camooweal State School",
    "franchise": "CQ"
    },
    {
    "id": 47281,
    "label": "Camp Hill State Infants and Primary School",
    "franchise": "SEQ"
    },
    {
    "id": "42168",
    "label": "Caniaba Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47376,
    "label": "Caningeraba State School",
    "franchise": "GCNR"
    },
    {
    "id": 48089,
    "label": "Cannon Hill Anglican College",
    "franchise": "SEQ"
    },
    {
    "id": 47152,
    "label": "Cannon Hill State School",
    "franchise": "SEQ"
    },
    {
    "id": 46799,
    "label": "Cannonvale State School",
    "franchise": "CQ"
    },
    {
    "id": 47995,
    "label": "Canterbury College",
    "franchise": "SEQ"
    },
    {
    "id": 46784,
    "label": "Canungra State School",
    "franchise": "SEQ"
    },
    {
    "id": 47632,
    "label": "Capalaba State College",
    "franchise": "SEQ"
    },
    {
    "id": "43779",
    "label": "Cape Byron Rudolf Steiner School",
    "franchise": "GCNR"
    },
    {
    "id": 50482,
    "label": "Cape York Aboriginal Australian Academy",
    "franchise": "CQ"
    },
    {
    "id": 47559,
    "label": "Capella State High School",
    "franchise": "CQ"
    },
    {
    "id": 46689,
    "label": "Capella State School",
    "franchise": "CQ"
    },
    {
    "id": 47628,
    "label": "Capricornia School of Distance Education",
    "franchise": "CQ"
    },
    {
    "id": 46949,
    "label": "Caravonica State School",
    "franchise": "CQ"
    },
    {
    "id": 52745,
    "label": "Carbrook Animal Assisted Learning Centre",
    "franchise": "SEQ"
    },
    {
    "id": 46602,
    "label": "Carbrook State School",
    "franchise": "SEQ"
    },
    {
    "id": 46502,
    "label": "Cardwell State School",
    "franchise": "CQ"
    },
    {
    "id": 47183,
    "label": "Carina State School",
    "franchise": "SEQ"
    },
    {
    "id": 47967,
    "label": "Carinity Education - Glendyne",
    "franchise": "CQ"
    },
    {
    "id": 52248,
    "label": "Carinity Education - Rockhampton",
    "franchise": "CQ"
    },
    {
    "id": 48044,
    "label": "Carinity Education - Shalom",
    "franchise": "CQ"
    },
    {
    "id": 47959,
    "label": "Carinity Education - Southside",
    "franchise": "SEQ"
    },
    {
    "id": 48023,
    "label": "Carlisle Adventist College",
    "franchise": "CQ"
    },
    {
    "id": 47924,
    "label": "Carmel College",
    "franchise": "SEQ"
    },
    {
    "id": 51519,
    "label": "Carmichael College",
    "franchise": "Brisbane"
    },
    {
    "id": 47269,
    "label": "Carmila State School",
    "franchise": "CQ"
    },
    {
    "id": 47002,
    "label": "Carole Park State School",
    "franchise": "SEQ"
    },
    {
    "id": "42169",
    "label": "Carool Public School",
    "franchise": "GCNR"
    },
    {
    "id": "43711",
    "label": "Casino Christian School",
    "franchise": "GCNR"
    },
    {
    "id": "42378",
    "label": "Casino High School",
    "franchise": "GCNR"
    },
    {
    "id": "42170",
    "label": "Casino Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42364",
    "label": "Casino West Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47425,
    "label": "Cavendish Road State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46501,
    "label": "Cawarral State School",
    "franchise": "CQ"
    },
    {
    "id": 46871,
    "label": "Cecil Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": 46520,
    "label": "Cedar Creek State School",
    "franchise": "SEQ"
    },
    {
    "id": "42359",
    "label": "Centaur Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47504,
    "label": "Centenary Heights State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47217,
    "label": "Centenary State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47570,
    "label": "Chancellor State College",
    "franchise": "Brisbane"
    },
    {
    "id": 47902,
    "label": "Chanel College",
    "franchise": "CQ"
    },
    {
    "id": 47295,
    "label": "Chapel Hill State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47623,
    "label": "Charleville School of Distance Education",
    "franchise": "NE"
    },
    {
    "id": 47461,
    "label": "Charleville State High School",
    "franchise": "NE"
    },
    {
    "id": 46543,
    "label": "Charleville State School",
    "franchise": "NE"
    },
    {
    "id": 52500,
    "label": "Charlotte Mason College",
    "franchise": "Brisbane"
    },
    {
    "id": 46562,
    "label": "Charters Towers Central State School",
    "franchise": "CQ"
    },
    {
    "id": 47626,
    "label": "Charters Towers School of Distance Education",
    "franchise": "CQ"
    },
    {
    "id": 47411,
    "label": "Charters Towers State High School",
    "franchise": "CQ"
    },
    {
    "id": 47337,
    "label": "Chatswood Hills State School",
    "franchise": "SEQ"
    },
    {
    "id": "42173",
    "label": "Chatsworth Island Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46942,
    "label": "Chatsworth State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46844,
    "label": "Chelona State School",
    "franchise": "CQ"
    },
    {
    "id": 46979,
    "label": "Cherbourg State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47239,
    "label": "Chevallum State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46785,
    "label": "Childers State School",
    "franchise": "CQ"
    },
    {
    "id": 46919,
    "label": "Chillagoe State School",
    "franchise": "CQ"
    },
    {
    "id": "42174",
    "label": "Chillingham Public School",
    "franchise": "GCNR"
    },
    {
    "id": 48086,
    "label": "Chinchilla Christian College",
    "franchise": "NE"
    },
    {
    "id": 47472,
    "label": "Chinchilla State High School",
    "franchise": "NE"
    },
    {
    "id": 46587,
    "label": "Chinchilla State School",
    "franchise": "NE"
    },
    {
    "id": 47888,
    "label": "Chisholm Catholic College",
    "franchise": "SEQ"
    },
    {
    "id": 47740,
    "label": "Christ the King Catholic Primary School (Deception Bay)",
    "franchise": "Brisbane"
    },
    {
    "id": 47672,
    "label": "Christ the King School (Graceville)",
    "franchise": "SEQ"
    },
    {
    "id": 47266,
    "label": "Churchill State School",
    "franchise": "SEQ"
    },
    {
    "id": 48102,
    "label": "Citipointe Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 47886,
    "label": "Clairvaux MacKillop College",
    "franchise": "SEQ"
    },
    {
    "id": 46530,
    "label": "Clare State School",
    "franchise": "CQ"
    },
    {
    "id": 47613,
    "label": "Claremont Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47245,
    "label": "Clarendon State School",
    "franchise": "SEQ"
    },
    {
    "id": 46811,
    "label": "Clarke Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 48012,
    "label": "Clayfield College",
    "franchise": "Brisbane"
    },
    {
    "id": 47562,
    "label": "Clermont State High School",
    "franchise": "CQ"
    },
    {
    "id": 46408,
    "label": "Clermont State School",
    "franchise": "CQ"
    },
    {
    "id": 47463,
    "label": "Cleveland District State High School",
    "franchise": "SEQ"
    },
    {
    "id": 40688,
    "label": "Cleveland Education and Training Centre",
    "franchise": "CQ"
    },
    {
    "id": 46409,
    "label": "Cleveland State School",
    "franchise": "SEQ"
    },
    {
    "id": 47619,
    "label": "Clifford Park Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47496,
    "label": "Clifton State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46849,
    "label": "Clifton State School",
    "franchise": "SEQ"
    },
    {
    "id": 46531,
    "label": "Clinton State School",
    "franchise": "CQ"
    },
    {
    "id": 46690,
    "label": "Cloncurry State School P-12",
    "franchise": "CQ"
    },
    {
    "id": 47476,
    "label": "Clontarf Beach State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46522,
    "label": "Clontarf Beach State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47576,
    "label": "Clover Hill State School",
    "franchise": "GCNR"
    },
    {
    "id": 47083,
    "label": "Cloyna State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42175",
    "label": "Clunes Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47069,
    "label": "Coalstoun Lakes State School",
    "franchise": "CQ"
    },
    {
    "id": "42176",
    "label": "Coffee Camp Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47366,
    "label": "Collingwood Park State School",
    "franchise": "SEQ"
    },
    {
    "id": "42178",
    "label": "Collins Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47549,
    "label": "Collinsville State High School",
    "franchise": "CQ"
    },
    {
    "id": 47230,
    "label": "Collinsville State School",
    "franchise": "CQ"
    },
    {
    "id": 47654,
    "label": "Columba Catholic College",
    "franchise": "CQ"
    },
    {
    "id": 46532,
    "label": "Comet State School",
    "franchise": "CQ"
    },
    {
    "id": 48026,
    "label": "Concordia Lutheran College",
    "franchise": "SEQ"
    },
    {
    "id": 46411,
    "label": "Condamine State School",
    "franchise": "NE"
    },
    {
    "id": "42180",
    "label": "Condong Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46701,
    "label": "Coningsby State School",
    "franchise": "CQ"
    },
    {
    "id": 47114,
    "label": "Conondale State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46535,
    "label": "Cooktown State School",
    "franchise": "CQ"
    },
    {
    "id": 46828,
    "label": "Coolabunia State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47207,
    "label": "Coolangatta State School",
    "franchise": "GCNR"
    },
    {
    "id": 47340,
    "label": "Coolnwynpin State School",
    "franchise": "SEQ"
    },
    {
    "id": 48097,
    "label": "Cooloola Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47991,
    "label": "Coolum Beach Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47544,
    "label": "Coolum State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47188,
    "label": "Coolum State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47545,
    "label": "Coombabah State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47324,
    "label": "Coombabah State School",
    "franchise": "GCNR"
    },
    {
    "id": 47946,
    "label": "Coomera Anglican College",
    "franchise": "GCNR"
    },
    {
    "id": 50368,
    "label": "Coomera Rivers State School",
    "franchise": "GCNR"
    },
    {
    "id": 47640,
    "label": "Coomera Springs State School",
    "franchise": "GCNR"
    },
    {
    "id": 46491,
    "label": "Coomera State School",
    "franchise": "GCNR"
    },
    {
    "id": 47117,
    "label": "Coominya State School",
    "franchise": "SEQ"
    },
    {
    "id": 47080,
    "label": "Coopers Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": "42183",
    "label": "Coorabell Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46807,
    "label": "Cooran State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47045,
    "label": "Cooroy State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47465,
    "label": "Coorparoo Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 46566,
    "label": "Coorparoo State School",
    "franchise": "SEQ"
    },
    {
    "id": 46418,
    "label": "Coowonga State School",
    "franchise": "CQ"
    },
    {
    "id": 47014,
    "label": "Cooyar State School",
    "franchise": "SEQ"
    },
    {
    "id": 47313,
    "label": "Coppabella State School",
    "franchise": "CQ"
    },
    {
    "id": "42185",
    "label": "Coraki Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46487,
    "label": "Cordalba State School",
    "franchise": "CQ"
    },
    {
    "id": 47449,
    "label": "Corinda State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46995,
    "label": "Corinda State School",
    "franchise": "SEQ"
    },
    {
    "id": "42188",
    "label": "Corndale Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42191",
    "label": "Crabbes Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47423,
    "label": "Craigslea State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46444,
    "label": "Craigslea State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47322,
    "label": "Cranbrook State School",
    "franchise": "CQ"
    },
    {
    "id": 46682,
    "label": "Crawford State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46882,
    "label": "Crescent Lagoon State School",
    "franchise": "CQ"
    },
    {
    "id": 47341,
    "label": "Crestmead State School",
    "franchise": "SEQ"
    },
    {
    "id": 46612,
    "label": "Crow's Nest State School",
    "franchise": "SEQ"
    },
    {
    "id": 46525,
    "label": "Croydon State School",
    "franchise": "CQ"
    },
    {
    "id": "42194",
    "label": "Crystal Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42195",
    "label": "Cudgen Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46609,
    "label": "Cunnamulla P-12 State School",
    "franchise": "NE"
    },
    {
    "id": 46653,
    "label": "Currajong State School",
    "franchise": "CQ"
    },
    {
    "id": 47610,
    "label": "Currimundi Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47291,
    "label": "Currimundi State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47606,
    "label": "Currumbin Community Special School",
    "franchise": "GCNR"
    },
    {
    "id": 47047,
    "label": "Currumbin State School",
    "franchise": "GCNR"
    },
    {
    "id": 46887,
    "label": "Currumbin Valley State School",
    "franchise": "GCNR"
    },
    {
    "id": 46932,
    "label": "Dagun State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46994,
    "label": "Daintree State School",
    "franchise": "CQ"
    },
    {
    "id": 46473,
    "label": "Daisy Hill State School",
    "franchise": "SEQ"
    },
    {
    "id": 47219,
    "label": "Dajarra State School",
    "franchise": "CQ"
    },
    {
    "id": 47521,
    "label": "Dakabin State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47395,
    "label": "Dakabin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48056,
    "label": "Dalby Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 46841,
    "label": "Dalby South State School",
    "franchise": "SEQ"
    },
    {
    "id": 47430,
    "label": "Dalby State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46414,
    "label": "Dalby State School",
    "franchise": "SEQ"
    },
    {
    "id": 46960,
    "label": "Dallarnil State School",
    "franchise": "CQ"
    },
    {
    "id": 46631,
    "label": "Dalveen State School",
    "franchise": "SEQ"
    },
    {
    "id": 48019,
    "label": "Darling Downs Christian School",
    "franchise": "SEQ"
    },
    {
    "id": 47315,
    "label": "Darling Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47594,
    "label": "Darling Point Special School",
    "franchise": "SEQ"
    },
    {
    "id": 52894,
    "label": "Darlingia Forest School",
    "franchise": "CQ"
    },
    {
    "id": 46760,
    "label": "Darlington State School",
    "franchise": "SEQ"
    },
    {
    "id": 47170,
    "label": "Darra State School",
    "franchise": "SEQ"
    },
    {
    "id": 46547,
    "label": "Dayboro State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47927,
    "label": "Deception Bay Flexible Learning Centre",
    "franchise": "Brisbane"
    },
    {
    "id": 47302,
    "label": "Deception Bay North State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47492,
    "label": "Deception Bay State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46581,
    "label": "Deception Bay State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52244,
    "label": "Deebing Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 46848,
    "label": "Delaneys Creek State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46677,
    "label": "Denison State School",
    "franchise": "CQ"
    },
    {
    "id": 46911,
    "label": "Depot Hill State School",
    "franchise": "CQ"
    },
    {
    "id": 47070,
    "label": "Dimbulah State School",
    "franchise": "CQ"
    },
    {
    "id": 46594,
    "label": "Dingo State School",
    "franchise": "CQ"
    },
    {
    "id": 46983,
    "label": "Dirranbandi P-10 State School",
    "franchise": "NE"
    },
    {
    "id": 51499,
    "label": "Discovery Christian College",
    "franchise": "CQ"
    },
    {
    "id": 48111,
    "label": "Djarragun College",
    "franchise": "CQ"
    },
    {
    "id": 46636,
    "label": "Doomadgee State School",
    "franchise": "CQ"
    },
    {
    "id": "40749",
    "label": "Dorroughby Environmental Education Centre",
    "franchise": "GCNR"
    },
    {
    "id": 47895,
    "label": "Downlands College",
    "franchise": "SEQ"
    },
    {
    "id": "42199",
    "label": "Drake Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46417,
    "label": "Drayton State School",
    "franchise": "SEQ"
    },
    {
    "id": 46931,
    "label": "Drillham State School",
    "franchise": "NE"
    },
    {
    "id": 46644,
    "label": "Duaringa State School",
    "franchise": "CQ"
    },
    {
    "id": 47048,
    "label": "Dulacca State School",
    "franchise": "NE"
    },
    {
    "id": 47263,
    "label": "Dundula State School",
    "franchise": "CQ"
    },
    {
    "id": "42202",
    "label": "Dungay Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46952,
    "label": "Dunkeld State School",
    "franchise": "NE"
    },
    {
    "id": "42203",
    "label": "Dunoon Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46892,
    "label": "Dunwich State School",
    "franchise": "SEQ"
    },
    {
    "id": 46966,
    "label": "Durack State School",
    "franchise": "SEQ"
    },
    {
    "id": "42204",
    "label": "Duranbah Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47216,
    "label": "Durong South State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42205",
    "label": "Durrumbul Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46750,
    "label": "Dutton Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 47535,
    "label": "Dysart State High School",
    "franchise": "CQ"
    },
    {
    "id": 46472,
    "label": "Dysart State School",
    "franchise": "CQ"
    },
    {
    "id": 46868,
    "label": "Eagle Junction State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47298,
    "label": "Eagleby South State School",
    "franchise": "SEQ"
    },
    {
    "id": 47383,
    "label": "Eagleby State School",
    "franchise": "SEQ"
    },
    {
    "id": 47631,
    "label": "Earnshaw State College",
    "franchise": "Brisbane"
    },
    {
    "id": 46938,
    "label": "East Ayr State School",
    "franchise": "CQ"
    },
    {
    "id": 46926,
    "label": "East Brisbane State School",
    "franchise": "SEQ"
    },
    {
    "id": 52972,
    "label": "Eaton College",
    "franchise": "CQ"
    },
    {
    "id": 46803,
    "label": "Eatons Hill State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46620,
    "label": "Edens Landing State School",
    "franchise": "SEQ"
    },
    {
    "id": 46579,
    "label": "Edge Hill State School",
    "franchise": "CQ"
    },
    {
    "id": 46794,
    "label": "Eidsvold State School",
    "franchise": "CQ"
    },
    {
    "id": 46420,
    "label": "Eight Mile Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": 46777,
    "label": "Eimeo Road State School",
    "franchise": "CQ"
    },
    {
    "id": 47258,
    "label": "El Arish State School",
    "franchise": "CQ"
    },
    {
    "id": 47560,
    "label": "Elanora State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47336,
    "label": "Elanora State School",
    "franchise": "GCNR"
    },
    {
    "id": 46962,
    "label": "Elimbah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47112,
    "label": "Elliott Heads State School",
    "franchise": "CQ"
    },
    {
    "id": "42208",
    "label": "Eltham Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47962,
    "label": "Emerald Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47319,
    "label": "Emerald North State School",
    "franchise": "CQ"
    },
    {
    "id": 47505,
    "label": "Emerald State High School",
    "franchise": "CQ"
    },
    {
    "id": 46652,
    "label": "Emerald State School",
    "franchise": "CQ"
    },
    {
    "id": "43806",
    "label": "Emmanuel Anglican College",
    "franchise": "GCNR"
    },
    {
    "id": 47649,
    "label": "Emmanuel Catholic Primary School",
    "franchise": "CQ"
    },
    {
    "id": 48094,
    "label": "Emmanuel College",
    "franchise": "GCNR"
    },
    {
    "id": 47658,
    "label": "Emmaus College (Jimboomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47901,
    "label": "Emmaus College (Park Avenue)",
    "franchise": "CQ"
    },
    {
    "id": "42209",
    "label": "Empire Vale Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46515,
    "label": "Emu Creek State School",
    "franchise": "SEQ"
    },
    {
    "id": 46729,
    "label": "Emu Park State School",
    "franchise": "CQ"
    },
    {
    "id": 50498,
    "label": "Endeavour Christian College",
    "franchise": "CQ"
    },
    {
    "id": 52737,
    "label": "Enkindle Village School",
    "franchise": "CQ"
    },
    {
    "id": 46421,
    "label": "Enoggera State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47054,
    "label": "Eromanga State School",
    "franchise": "NE"
    },
    {
    "id": 46553,
    "label": "Esk State School",
    "franchise": "SEQ"
    },
    {
    "id": 46625,
    "label": "Eton State School",
    "franchise": "CQ"
    },
    {
    "id": 46847,
    "label": "Eudlo State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46568,
    "label": "Eulo State School",
    "franchise": "NE"
    },
    {
    "id": 46854,
    "label": "Eumundi State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47115,
    "label": "Eungella State School",
    "franchise": "CQ"
    },
    {
    "id": "42211",
    "label": "Eureka Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42212",
    "label": "Evans River Community School",
    "franchise": "GCNR"
    },
    {
    "id": 47454,
    "label": "Everton Park State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47055,
    "label": "Everton Park State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48015,
    "label": "Fairholme College",
    "franchise": "SEQ"
    },
    {
    "id": 47253,
    "label": "Fairview Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47949,
    "label": "Faith Baptist Christian School",
    "franchise": "CQ"
    },
    {
    "id": 52242,
    "label": "Faith Christian School of Distance Education",
    "franchise": "SEQ"
    },
    {
    "id": 47958,
    "label": "Faith Lutheran College - Plainland",
    "franchise": "SEQ"
    },
    {
    "id": 48074,
    "label": "Faith Lutheran College - Redlands",
    "franchise": "SEQ"
    },
    {
    "id": 47049,
    "label": "Farleigh State School",
    "franchise": "CQ"
    },
    {
    "id": 46997,
    "label": "Farnborough State School",
    "franchise": "CQ"
    },
    {
    "id": 47064,
    "label": "Federal State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46904,
    "label": "Feluga State School",
    "franchise": "CQ"
    },
    {
    "id": 52460,
    "label": "Fernbrooke State School",
    "franchise": "SEQ"
    },
    {
    "id": "42233",
    "label": "Fernleigh Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46529,
    "label": "Fernvale State School",
    "franchise": "SEQ"
    },
    {
    "id": 47527,
    "label": "Ferny Grove State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46573,
    "label": "Ferny Grove State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46590,
    "label": "Ferny Hills State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46422,
    "label": "Fig Tree Pocket State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47052,
    "label": "Finch Hatton State School",
    "franchise": "CQ"
    },
    {
    "id": "42213",
    "label": "Fingal Head Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47301,
    "label": "Fitzgerald State School",
    "franchise": "CQ"
    },
    {
    "id": 46741,
    "label": "Flagstone Creek State School",
    "franchise": "SEQ"
    },
    {
    "id": 47573,
    "label": "Flagstone State Community College",
    "franchise": "SEQ"
    },
    {
    "id": 46727,
    "label": "Flagstone State School",
    "franchise": "SEQ"
    },
    {
    "id": 46914,
    "label": "Flying Fish Point State School",
    "franchise": "CQ"
    },
    {
    "id": 46853,
    "label": "Forest Hill State School",
    "franchise": "SEQ"
    },
    {
    "id": 47243,
    "label": "Forest Lake State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47405,
    "label": "Forest Lake State School",
    "franchise": "SEQ"
    },
    {
    "id": 47312,
    "label": "Forrest Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 46763,
    "label": "Forsayth State School",
    "franchise": "CQ"
    },
    {
    "id": 52802,
    "label": "Fortitude Valley State Secondary College",
    "franchise": "Brisbane"
    },
    {
    "id": 52804,
    "label": "Foxwell State Secondary College",
    "franchise": "GCNR"
    },
    {
    "id": 47936,
    "label": "Fraser Coast Anglican College",
    "franchise": "CQ"
    },
    {
    "id": 46426,
    "label": "Freestone State School",
    "franchise": "SEQ"
    },
    {
    "id": 46954,
    "label": "Frenchville State School",
    "franchise": "CQ"
    },
    {
    "id": 48108,
    "label": "Freshwater Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47271,
    "label": "Freshwater State School",
    "franchise": "CQ"
    },
    {
    "id": 46466,
    "label": "Gabbinbar State School",
    "franchise": "SEQ"
    },
    {
    "id": 52888,
    "label": "Gainsborough State School",
    "franchise": "GCNR"
    },
    {
    "id": 47140,
    "label": "Garbutt State School",
    "franchise": "CQ"
    },
    {
    "id": 47142,
    "label": "Gargett State School",
    "franchise": "CQ"
    },
    {
    "id": 46427,
    "label": "Gatton State School",
    "franchise": "SEQ"
    },
    {
    "id": 47254,
    "label": "Gaven State School",
    "franchise": "GCNR"
    },
    {
    "id": 46428,
    "label": "Gayndah State School",
    "franchise": "CQ"
    },
    {
    "id": 47614,
    "label": "Geebung Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47084,
    "label": "Geebung State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46439,
    "label": "Geham State School",
    "franchise": "SEQ"
    },
    {
    "id": 48062,
    "label": "Genesis Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 46536,
    "label": "Georgetown State School",
    "franchise": "CQ"
    },
    {
    "id": 47864,
    "label": "Gilroy Santa Maria College",
    "franchise": "CQ"
    },
    {
    "id": 46435,
    "label": "Gilston State School",
    "franchise": "GCNR"
    },
    {
    "id": 47512,
    "label": "Gin Gin State High School",
    "franchise": "CQ"
    },
    {
    "id": 46617,
    "label": "Gin Gin State School",
    "franchise": "CQ"
    },
    {
    "id": 46859,
    "label": "Gindie State School",
    "franchise": "CQ"
    },
    {
    "id": 50392,
    "label": "Girl Academy",
    "franchise": "CQ"
    },
    {
    "id": 47085,
    "label": "Giru State School",
    "franchise": "CQ"
    },
    {
    "id": 46430,
    "label": "Gladstone Central State School",
    "franchise": "CQ"
    },
    {
    "id": 46909,
    "label": "Gladstone South State School",
    "franchise": "CQ"
    },
    {
    "id": 47428,
    "label": "Gladstone State High School",
    "franchise": "CQ"
    },
    {
    "id": 46874,
    "label": "Gladstone West State School",
    "franchise": "CQ"
    },
    {
    "id": 46510,
    "label": "Glamorgan Vale State School",
    "franchise": "SEQ"
    },
    {
    "id": 47076,
    "label": "Glass House Mountains State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47961,
    "label": "Glasshouse Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 46776,
    "label": "Glen Aplin State School",
    "franchise": "SEQ"
    },
    {
    "id": 47194,
    "label": "Glenala State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47331,
    "label": "Glenden State School",
    "franchise": "CQ"
    },
    {
    "id": 46986,
    "label": "Gleneagle State School",
    "franchise": "SEQ"
    },
    {
    "id": 46628,
    "label": "Glenella State School",
    "franchise": "CQ"
    },
    {
    "id": 47427,
    "label": "Glenmore State High School",
    "franchise": "CQ"
    },
    {
    "id": 46789,
    "label": "Glenmore State School",
    "franchise": "CQ"
    },
    {
    "id": 46943,
    "label": "Glenmorgan State School",
    "franchise": "NE"
    },
    {
    "id": 46883,
    "label": "Glennie Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47015,
    "label": "Glenore Grove State School",
    "franchise": "SEQ"
    },
    {
    "id": 47951,
    "label": "Glenvale Christian School",
    "franchise": "SEQ"
    },
    {
    "id": 46493,
    "label": "Glenvale State School",
    "franchise": "SEQ"
    },
    {
    "id": 46641,
    "label": "Glenview State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47204,
    "label": "Glenwood State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46781,
    "label": "Gogango State School",
    "franchise": "CQ"
    },
    {
    "id": 48017,
    "label": "Gold Coast Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 47333,
    "label": "Golden Beach State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46687,
    "label": "Gooburrum State School",
    "franchise": "CQ"
    },
    {
    "id": 47917,
    "label": "Good Counsel College (Innisfail)",
    "franchise": "CQ"
    },
    {
    "id": 47832,
    "label": "Good Counsel Primary School (Innisfail)",
    "franchise": "CQ"
    },
    {
    "id": 48078,
    "label": "Good News Lutheran School",
    "franchise": "SEQ"
    },
    {
    "id": 52501,
    "label": "Good Samaritan Catholic College",
    "franchise": "Brisbane"
    },
    {
    "id": 47660,
    "label": "Good Samaritan College",
    "franchise": "SEQ"
    },
    {
    "id": 47907,
    "label": "Good Shepherd Catholic College (Mount Isa)",
    "franchise": "CQ"
    },
    {
    "id": 50581,
    "label": "Good Shepherd Catholic Primary School (Springfield Lakes)",
    "franchise": "SEQ"
    },
    {
    "id": 47648,
    "label": "Good Shepherd Catholic School (Rasmussen)",
    "franchise": "CQ"
    },
    {
    "id": 47984,
    "label": "Good Shepherd Christian School (Albany Creek)",
    "franchise": "Brisbane"
    },
    {
    "id": 48039,
    "label": "Good Shepherd Lutheran College (Noosaville)",
    "franchise": "Brisbane"
    },
    {
    "id": 47600,
    "label": "Goodna Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46431,
    "label": "Goodna State School",
    "franchise": "SEQ"
    },
    {
    "id": 46953,
    "label": "Goodwood State School",
    "franchise": "CQ"
    },
    {
    "id": "42219",
    "label": "Goolmangar Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46481,
    "label": "Goombungee State School",
    "franchise": "SEQ"
    },
    {
    "id": 47102,
    "label": "Goomeri State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46903,
    "label": "Goondi State School",
    "franchise": "CQ"
    },
    {
    "id": 47477,
    "label": "Goondiwindi State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46432,
    "label": "Goondiwindi State School",
    "franchise": "SEQ"
    },
    {
    "id": "42220",
    "label": "Goonellabah Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42221",
    "label": "Goonengerry Public School",
    "franchise": "GCNR"
    },
    {
    "id": 52668,
    "label": "Goora Gan Steiner School",
    "franchise": "CQ"
    },
    {
    "id": 47276,
    "label": "Goovigen State School",
    "franchise": "CQ"
    },
    {
    "id": 47491,
    "label": "Gordonvale State High School",
    "franchise": "CQ"
    },
    {
    "id": 46896,
    "label": "Gordonvale State School",
    "franchise": "CQ"
    },
    {
    "id": 46616,
    "label": "Gowrie State School",
    "franchise": "SEQ"
    },
    // {
    // "id": 48083,
    // "label": "Grace Lutheran College",
    // "franchise": "Brisbane"
    // },
    {
    "id": 48071,
    "label": "Grace Lutheran Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 46433,
    "label": "Gracemere State School",
    "franchise": "CQ"
    },
    {
    "id": 47168,
    "label": "Graceville State School",
    "franchise": "SEQ"
    },
    {
    "id": 47222,
    "label": "Grand Avenue State School",
    "franchise": "SEQ"
    },
    {
    "id": 46508,
    "label": "Grandchester State School",
    "franchise": "SEQ"
    },
    {
    "id": 47004,
    "label": "Grantham State School",
    "franchise": "SEQ"
    },
    {
    "id": 46549,
    "label": "Granville State School",
    "franchise": "CQ"
    },
    {
    "id": 46852,
    "label": "Greenbank State School",
    "franchise": "SEQ"
    },
    {
    "id": 46947,
    "label": "Greenlands State School",
    "franchise": "SEQ"
    },
    {
    "id": 46973,
    "label": "Greenmount State School",
    "franchise": "SEQ"
    },
    {
    "id": 46816,
    "label": "Greenslopes State School",
    "franchise": "SEQ"
    },
    {
    "id": 46639,
    "label": "Greenvale State School",
    "franchise": "CQ"
    },
    {
    "id": 52245,
    "label": "Griffin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47033,
    "label": "Grovely State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47956,
    "label": "Groves Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 47744,
    "label": "Guardian Angels Primary School (Ashmore)",
    "franchise": "GCNR"
    },
    {
    "id": 47674,
    "label": "Guardian Angels' Primary School (Wynnum)",
    "franchise": "SEQ"
    },
    {
    "id": 48112,
    "label": "Gulf Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47181,
    "label": "Guluguba State School",
    "franchise": "NE"
    },
    {
    "id": 47201,
    "label": "Gumdale State School",
    "franchise": "SEQ"
    },
    {
    "id": 47126,
    "label": "Gumlu State School",
    "franchise": "CQ"
    },
    {
    "id": 46442,
    "label": "Gunalda State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46907,
    "label": "Gundiah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46434,
    "label": "Gympie Central State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46736,
    "label": "Gympie East State School",
    "franchise": "Brisbane"
    },
    {
    "id": 50478,
    "label": "Gympie Flexible Learning Centre",
    "franchise": "Brisbane"
    },
    {
    "id": 47067,
    "label": "Gympie South State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47585,
    "label": "Gympie Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47412,
    "label": "Gympie State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46783,
    "label": "Gympie West State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47108,
    "label": "Haden State School",
    "franchise": "SEQ"
    },
    {
    "id": 46484,
    "label": "Haigslea State School",
    "franchise": "SEQ"
    },
    {
    "id": 46561,
    "label": "Halifax State School",
    "franchise": "CQ"
    },
    {
    "id": 46773,
    "label": "Hambledon State School",
    "franchise": "CQ"
    },
    {
    "id": 47372,
    "label": "Hamilton Island State School",
    "franchise": "CQ"
    },
    {
    "id": 47030,
    "label": "Hamilton State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46768,
    "label": "Hampden State School",
    "franchise": "CQ"
    },
    {
    "id": 47279,
    "label": "Hannaford State School",
    "franchise": "NE"
    },
    {
    "id": 46912,
    "label": "Happy Valley State School",
    "franchise": "CQ"
    },
    {
    "id": 46974,
    "label": "Harlaxton State School",
    "franchise": "SEQ"
    },
    {
    "id": 46936,
    "label": "Harlin State School",
    "franchise": "SEQ"
    },
    {
    "id": 46657,
    "label": "Harris Fields State School",
    "franchise": "SEQ"
    },
    {
    "id": 47433,
    "label": "Harristown State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47094,
    "label": "Harristown State School",
    "franchise": "SEQ"
    },
    {
    "id": 46821,
    "label": "Harrisville State School",
    "franchise": "SEQ"
    },
    {
    "id": "42228",
    "label": "Harwood Island Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46676,
    "label": "Hatton Vale State School",
    "franchise": "SEQ"
    },
    {
    "id": 47006,
    "label": "Hayman Island State School",
    "franchise": "CQ"
    },
    {
    "id": 46577,
    "label": "Healy State School",
    "franchise": "CQ"
    },
    {
    "id": 47503,
    "label": "Heatley Secondary College",
    "franchise": "CQ"
    },
    {
    "id": 46623,
    "label": "Heatley State School",
    "franchise": "CQ"
    },
    {
    "id": 46963,
    "label": "Hebel State School",
    "franchise": "NE"
    },
    {
    "id": 48113,
    "label": "Heights College",
    "franchise": "CQ"
    },
    {
    "id": 47561,
    "label": "Helensvale State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47342,
    "label": "Helensvale State School",
    "franchise": "GCNR"
    },
    {
    "id": 46437,
    "label": "Helidon State School",
    "franchise": "SEQ"
    },
    {
    "id": 50553,
    "label": "Hemmant Flexible Learning Centre",
    "franchise": "SEQ"
    },
    {
    "id": 46419,
    "label": "Hendra State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46591,
    "label": "Herberton State School",
    "franchise": "CQ"
    },
    {
    "id": 47284,
    "label": "Hercules Road State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46925,
    "label": "Hermit Park State School",
    "franchise": "CQ"
    },
    {
    "id": 52824,
    "label": "Hervey Bay Future School",
    "franchise": "CQ"
    },
    {
    "id": 47612,
    "label": "Hervey Bay Special School",
    "franchise": "CQ"
    },
    {
    "id": 47478,
    "label": "Hervey Bay State High School",
    "franchise": "CQ"
    },
    {
    "id": 46438,
    "label": "Highfields State School",
    "franchise": "SEQ"
    },
    {
    "id": 51493,
    "label": "Highfields State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 47642,
    "label": "Highland Reserve State School",
    "franchise": "GCNR"
    },
    {
    "id": 48099,
    "label": "Highlands Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 47305,
    "label": "Hilder Road State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47993,
    "label": "Hillbrook Anglican School",
    "franchise": "Brisbane"
    },
    {
    "id": 48047,
    "label": "Hillcrest Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 47393,
    "label": "Hilliard State School",
    "franchise": "SEQ"
    },
    {
    "id": 48090,
    "label": "Hills International College",
    "franchise": "SEQ"
    },
    {
    "id": 46775,
    "label": "Hillview State School",
    "franchise": "SEQ"
    },
    {
    "id": 40674,
    "label": "Hinchinbrook Christian School",
    "franchise": "CQ"
    },
    {
    "id": "43767",
    "label": "Hinterland Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 47508,
    "label": "Holland Park State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47195,
    "label": "Holland Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 47933,
    "label": "Holy Cross School (Trinity Park)",
    "franchise": "CQ"
    },
    {
    "id": 47675,
    "label": "Holy Cross School (Wooloowin)",
    "franchise": "Brisbane"
    },
    {
    "id": "43342",
    "label": "Holy Family Catholic Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47676,
    "label": "Holy Family Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 47766,
    "label": "Holy Name Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47817,
    "label": "Holy Spirit Catholic School (Townsville)",
    "franchise": "CQ"
    },
    {
    "id": 51504,
    "label": "Holy Spirit College (Cooktown)",
    "franchise": "CQ"
    },
    {
    "id": 47650,
    "label": "Holy Spirit College (Mount Pleasant)",
    "franchise": "CQ"
    },
    {
    "id": 47679,
    "label": "Holy Spirit School (Bray Park)",
    "franchise": "Brisbane"
    },
    {
    "id": 47678,
    "label": "Holy Spirit School (New Farm)",
    "franchise": "Brisbane"
    },
    {
    "id": 47479,
    "label": "Home Hill State High School",
    "franchise": "CQ"
    },
    {
    "id": 47121,
    "label": "Home Hill State School",
    "franchise": "CQ"
    },
    {
    "id": 46726,
    "label": "Homebush State School",
    "franchise": "CQ"
    },
    {
    "id": 46584,
    "label": "Homestead State School",
    "franchise": "CQ"
    },
    {
    "id": 48020,
    "label": "Hope Adventist School",
    "franchise": "CQ"
    },
    {
    "id": 50728,
    "label": "Horizons College of Learning and Enrichment",
    "franchise": "Brisbane"
    },
    {
    "id": 46647,
    "label": "Howard State School",
    "franchise": "CQ"
    },
    {
    "id": 40701,
    "label": "Hubbard's School",
    "franchise": "Brisbane"
    },
    {
    "id": 46675,
    "label": "Hughenden State School",
    "franchise": "CQ"
    },
    {
    "id": 53025,
    "label": "Humanitas High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46524,
    "label": "Humpybong State School",
    "franchise": "Brisbane"
    },
    {
    "id": 50374,
    "label": "Hymba Yumba Independent School",
    "franchise": "SEQ"
    },
    {
    "id": 52743,
    "label": "IES College",
    "franchise": "Brisbane"
    },
    {
    "id": 47909,
    "label": "Ignatius Park College",
    "franchise": "CQ"
    },
    {
    "id": 46862,
    "label": "Ilfracombe State School",
    "franchise": "CQ"
    },
    {
    "id": 47680,
    "label": "Immaculate Heart School",
    "franchise": "SEQ"
    },
    {
    "id": 48027,
    "label": "Immanuel Lutheran College",
    "franchise": "Brisbane"
    },
    {
    "id": 50580,
    "label": "Inala Flexible Learning Centre",
    "franchise": "SEQ"
    },
    {
    "id": 47022,
    "label": "Inala State School",
    "franchise": "SEQ"
    },
    {
    "id": 47431,
    "label": "Indooroopilly State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46798,
    "label": "Indooroopilly State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47426,
    "label": "Ingham State High School",
    "franchise": "CQ"
    },
    {
    "id": 46708,
    "label": "Ingham State School",
    "franchise": "CQ"
    },
    {
    "id": 46836,
    "label": "Ingleside State School",
    "franchise": "GCNR"
    },
    {
    "id": 46440,
    "label": "Inglewood State School",
    "franchise": "SEQ"
    },
    {
    "id": 47240,
    "label": "Injune P-10 State School",
    "franchise": "NE"
    },
    {
    "id": 47214,
    "label": "Innisfail East State School",
    "franchise": "CQ"
    },
    {
    "id": 40838,
    "label": "Innisfail State College",
    "franchise": "CQ"
    },
    {
    "id": 46767,
    "label": "Innisfail State School",
    "franchise": "CQ"
    },
    {
    "id": 47849,
    "label": "Iona College",
    "franchise": "SEQ"
    },
    {
    "id": 48018,
    "label": "Ipswich Adventist School",
    "franchise": "SEQ"
    },
    {
    "id": 46443,
    "label": "Ipswich Central State School",
    "franchise": "SEQ"
    },
    {
    "id": 46790,
    "label": "Ipswich East State School",
    "franchise": "SEQ"
    },
    {
    "id": 50479,
    "label": "Ipswich Flexible Learning Centre",
    "franchise": "SEQ"
    },
    {
    "id": 48035,
    "label": "Ipswich Girls' Grammar School",
    "franchise": "SEQ"
    },
    {
    "id": 48036,
    "label": "Ipswich Grammar School",
    "franchise": "SEQ"
    },
    {
    "id": 46445,
    "label": "Ipswich North State School",
    "franchise": "SEQ"
    },
    {
    "id": 47593,
    "label": "Ipswich Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47462,
    "label": "Ipswich State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47591,
    "label": "Ipswich West Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46661,
    "label": "Ipswich West State School",
    "franchise": "SEQ"
    },
    {
    "id": 46479,
    "label": "Ironside State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46759,
    "label": "Irvinebank State School",
    "franchise": "CQ"
    },
    {
    "id": 47238,
    "label": "Isabella State School",
    "franchise": "CQ"
    },
    {
    "id": 47456,
    "label": "Isis District State High School",
    "franchise": "CQ"
    },
    {
    "id": 46447,
    "label": "Isisford State School",
    "franchise": "CQ"
    },
    {
    "id": 47938,
    "label": "Islamic College of Brisbane",
    "franchise": "SEQ"
    },
    {
    "id": 46712,
    "label": "Ithaca Creek State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52688,
    "label": "Jabiru Community College",
    "franchise": "Brisbane"
    },
    {
    "id": 47213,
    "label": "Jambin State School",
    "franchise": "CQ"
    },
    {
    "id": 46600,
    "label": "Jamboree Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47518,
    "label": "James Nash State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46772,
    "label": "Jandowae Prep-10 State School",
    "franchise": "SEQ"
    },
    {
    "id": 46898,
    "label": "Jarvisfield State School",
    "franchise": "CQ"
    },
    {
    "id": 46556,
    "label": "Jericho State School",
    "franchise": "CQ"
    },
    {
    "id": "42234",
    "label": "Jiggi Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46809,
    "label": "Jimboomba State School",
    "franchise": "SEQ"
    },
    {
    "id": 47184,
    "label": "Jimbour State School",
    "franchise": "SEQ"
    },
    {
    "id": 46684,
    "label": "Jindalee State School",
    "franchise": "SEQ"
    },
    {
    "id": 46538,
    "label": "Jinibara State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48060,
    "label": "John Paul College",
    "franchise": "SEQ"
    },
    {
    "id": 46448,
    "label": "Jondaryan State School",
    "franchise": "SEQ"
    },
    {
    "id": 46832,
    "label": "Jones Hill State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52604,
    "label": "Josiah College",
    "franchise": "GCNR"
    },
    {
    "id": 48104,
    "label": "Jubilee Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47656,
    "label": "Jubilee Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47270,
    "label": "Julatten State School",
    "franchise": "CQ"
    },
    {
    "id": 47092,
    "label": "Julia Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46755,
    "label": "Junction Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 46939,
    "label": "Jundah State School",
    "franchise": "NE"
    },
    {
    "id": 47056,
    "label": "Kaimkillenbun State School",
    "franchise": "SEQ"
    },
    {
    "id": 47091,
    "label": "Kairi State School",
    "franchise": "CQ"
    },
    {
    "id": 51496,
    "label": "Kairos Community College",
    "franchise": "Brisbane"
    },
    {
    "id": 47162,
    "label": "Kalamia State School",
    "franchise": "CQ"
    },
    {
    "id": 46645,
    "label": "Kalbar State School",
    "franchise": "SEQ"
    },
    {
    "id": 46601,
    "label": "Kalkie State School",
    "franchise": "CQ"
    },
    {
    "id": 46810,
    "label": "Kallangur State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47153,
    "label": "Kandanga State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47348,
    "label": "Karalee State School",
    "franchise": "SEQ"
    },
    {
    "id": 47037,
    "label": "Karara State School",
    "franchise": "SEQ"
    },
    {
    "id": 46707,
    "label": "Karumba State School",
    "franchise": "CQ"
    },
    {
    "id": 47636,
    "label": "Kawana Waters State College",
    "franchise": "Brisbane"
    },
    {
    "id": 47394,
    "label": "Kawungan State School",
    "franchise": "CQ"
    },
    {
    "id": 47434,
    "label": "Kedron State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46665,
    "label": "Kedron State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47440,
    "label": "Keebra Park State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47370,
    "label": "Kelso State School",
    "franchise": "CQ"
    },
    {
    "id": 47578,
    "label": "Kelvin Grove State College",
    "franchise": "Brisbane"
    },
    {
    "id": 47101,
    "label": "Kenilworth State Community College",
    "franchise": "Brisbane"
    },
    {
    "id": 47036,
    "label": "Kenmore South State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47513,
    "label": "Kenmore State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46946,
    "label": "Kenmore State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46921,
    "label": "Kennedy State School",
    "franchise": "CQ"
    },
    {
    "id": 46860,
    "label": "Kentville State School",
    "franchise": "SEQ"
    },
    {
    "id": 47480,
    "label": "Kepnock State High School",
    "franchise": "CQ"
    },
    {
    "id": 46840,
    "label": "Keppel Sands State School",
    "franchise": "CQ"
    },
    {
    "id": 47233,
    "label": "Kia-Ora State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47514,
    "label": "Kilcoy State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46845,
    "label": "Kilcoy State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46894,
    "label": "Kilcummin State School",
    "franchise": "CQ"
    },
    {
    "id": 46528,
    "label": "Kilkivan State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46523,
    "label": "Killarney P-10 State School",
    "franchise": "SEQ"
    },
    {
    "id": 47960,
    "label": "Kimberley College",
    "franchise": "SEQ"
    },
    {
    "id": 47347,
    "label": "Kimberley Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 47175,
    "label": "Kin Kin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47326,
    "label": "Kin Kora State School",
    "franchise": "CQ"
    },
    {
    "id": 46877,
    "label": "Kindon State School",
    "franchise": "SEQ"
    },
    {
    "id": 47438,
    "label": "Kingaroy State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46981,
    "label": "Kingaroy State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48055,
    "label": "King's Christian College (Reedy Creek)",
    "franchise": "GCNR"
    },
    {
    "id": "42406",
    "label": "Kingscliff High School",
    "franchise": "GCNR"
    },
    {
    "id": "42334",
    "label": "Kingscliff Public School",
    "franchise": "GCNR"
    },
    {
    "id": 48064,
    "label": "Kingsley College",
    "franchise": "CQ"
    },
    {
    "id": 47088,
    "label": "Kingsthorpe State School",
    "franchise": "SEQ"
    },
    {
    "id": 47519,
    "label": "Kingston State College",
    "franchise": "SEQ"
    },
    {
    "id": 47113,
    "label": "Kingston State School",
    "franchise": "SEQ"
    },
    {
    "id": 40596,
    "label": "Kioma State School",
    "franchise": "SEQ"
    },
    {
    "id": 46818,
    "label": "Kippa-Ring State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47525,
    "label": "Kirwan State High School",
    "franchise": "CQ"
    },
    {
    "id": 47285,
    "label": "Kirwan State School",
    "franchise": "CQ"
    },
    {
    "id": 47013,
    "label": "Kogan State School",
    "franchise": "NE"
    },
    {
    "id": 46603,
    "label": "Kolan South State School",
    "franchise": "CQ"
    },
    {
    "id": 47264,
    "label": "Koumala State School",
    "franchise": "CQ"
    },
    {
    "id": 46716,
    "label": "Kowanyama State School",
    "franchise": "CQ"
    },
    {
    "id": 47300,
    "label": "Kruger State School",
    "franchise": "SEQ"
    },
    {
    "id": 47149,
    "label": "Kulpi State School",
    "franchise": "SEQ"
    },
    {
    "id": 47377,
    "label": "Kuluin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47137,
    "label": "Kumbia State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47604,
    "label": "Kuraby Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47143,
    "label": "Kuraby State School",
    "franchise": "SEQ"
    },
    {
    "id": 47638,
    "label": "Kuranda District State College",
    "franchise": "CQ"
    },
    {
    "id": 47368,
    "label": "Kurwongbah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52838,
    "label": "Kutta Mulla Gorinna Special Assistance School",
    "franchise": "CQ"
    },
    {
    "id": "42381",
    "label": "Kyogle High School",
    "franchise": "GCNR"
    },
    {
    "id": "42244",
    "label": "Kyogle Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46704,
    "label": "Labrador State School",
    "franchise": "GCNR"
    },
    {
    "id": 47224,
    "label": "Laidley District State School",
    "franchise": "SEQ"
    },
    {
    "id": 47542,
    "label": "Laidley State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46989,
    "label": "Lake Clarendon State School",
    "franchise": "SEQ"
    },
    {
    "id": 46555,
    "label": "Lakeland State School",
    "franchise": "CQ"
    },
    {
    "id": 46450,
    "label": "Lakes Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46648,
    "label": "Landsborough State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42247",
    "label": "Larnook Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46796,
    "label": "Laura State School",
    "franchise": "CQ"
    },
    {
    "id": 46635,
    "label": "Lawnton State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52807,
    "label": "Lee Street State Special School",
    "franchise": "Brisbane"
    },
    {
    "id": "42197",
    "label": "Leeville Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47039,
    "label": "Leichhardt State School",
    "franchise": "SEQ"
    },
    {
    "id": "42340",
    "label": "Lennox Head Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46451,
    "label": "Leyburn State School",
    "franchise": "SEQ"
    },
    {
    "id": 47935,
    "label": "Lighthouse Christian School",
    "franchise": "CQ"
    },
    {
    "id": "43999",
    "label": "Lindisfarne Anglican Grammar School",
    "franchise": "GCNR"
    },
    {
    "id": "40895",
    "label": "Lindisfarne Anglican Grammar School, Junior School",
    "franchise": "GCNR"
    },
    {
    "id": 46975,
    "label": "Linville State School",
    "franchise": "SEQ"
    },
    {
    "id": "42335",
    "label": "Lismore Heights Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42200",
    "label": "Lismore Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42250",
    "label": "Lismore South Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47965,
    "label": "Living Faith Lutheran Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": "52825",
    "label": "Living School",
    "franchise": "GCNR"
    },
    {
    "id": 47968,
    "label": "Livingstone Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 47024,
    "label": "Lochington State School",
    "franchise": "CQ"
    },
    {
    "id": 46749,
    "label": "Lockhart State School",
    "franchise": "CQ"
    },
    {
    "id": 47007,
    "label": "Lockrose State School",
    "franchise": "SEQ"
    },
    {
    "id": 47455,
    "label": "Lockyer District State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47580,
    "label": "Logan City Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46452,
    "label": "Logan Reserve State School",
    "franchise": "SEQ"
    },
    {
    "id": 46490,
    "label": "Logan Village State School",
    "franchise": "SEQ"
    },
    {
    "id": 46453,
    "label": "Loganholme State School",
    "franchise": "SEQ"
    },
    {
    "id": 47532,
    "label": "Loganlea State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47627,
    "label": "Longreach School of Distance Education",
    "franchise": "CQ"
    },
    {
    "id": 47497,
    "label": "Longreach State High School",
    "franchise": "CQ"
    },
    {
    "id": 46861,
    "label": "Longreach State School",
    "franchise": "CQ"
    },
    {
    "id": 47870,
    "label": "Loreto College Coorparoo",
    "franchise": "SEQ"
    },
    {
    "id": 46930,
    "label": "Lota State School",
    "franchise": "SEQ"
    },
    {
    "id": 47871,
    "label": "Lourdes Hill College",
    "franchise": "SEQ"
    },
    {
    "id": 46990,
    "label": "Lower Tully State School",
    "franchise": "CQ"
    },
    {
    "id": 47034,
    "label": "Lowmead State School",
    "franchise": "CQ"
    },
    {
    "id": 47539,
    "label": "Lowood State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46585,
    "label": "Lowood State School",
    "franchise": "SEQ"
    },
    {
    "id": 40589,
    "label": "Lundavra State School",
    "franchise": "SEQ"
    },
    {
    "id": 50390,
    "label": "Lutheran Ormeau Rivers District School",
    "franchise": "GCNR"
    },
    {
    "id": 46672,
    "label": "Ma Ma Creek State School",
    "franchise": "SEQ"
    },
    {
    "id": 47540,
    "label": "Mabel Park State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46654,
    "label": "Mabel Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 47507,
    "label": "MacGregor State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46548,
    "label": "MacGregor State School",
    "franchise": "SEQ"
    },
    {
    "id": 46879,
    "label": "Machans Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 46454,
    "label": "Mackay Central State School",
    "franchise": "CQ"
    },
    {
    "id": 48109,
    "label": "Mackay Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47616,
    "label": "Mackay District Special School",
    "franchise": "CQ"
    },
    {
    "id": 47481,
    "label": "Mackay North State High School",
    "franchise": "CQ"
    },
    {
    "id": 46875,
    "label": "Mackay North State School",
    "franchise": "CQ"
    },
    {
    "id": 50535,
    "label": "Mackay Northern Beaches State High School",
    "franchise": "CQ"
    },
    {
    "id": 47414,
    "label": "Mackay State High School",
    "franchise": "CQ"
    },
    {
    "id": 46748,
    "label": "Mackay West State School",
    "franchise": "CQ"
    },
    {
    "id": 46812,
    "label": "Mackenzie State Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47589,
    "label": "Mackenzie State Special School",
    "franchise": "SEQ"
    },
    {
    "id": 51501,
    "label": "MacKillop Catholic College, Mount Peter",
    "franchise": "CQ"
    },
    {
    "id": 47646,
    "label": "MacKillop Catholic Primary School (Andergrove)",
    "franchise": "CQ"
    },
    {
    "id": 46634,
    "label": "Macknade State School",
    "franchise": "CQ"
    },
    {
    "id": 47369,
    "label": "MacLeay Island State School",
    "franchise": "SEQ"
    },
    {
    "id": 47066,
    "label": "Magnetic Island State School",
    "franchise": "CQ"
    },
    {
    "id": 47065,
    "label": "Maidavale State School",
    "franchise": "CQ"
    },
    {
    "id": "42301",
    "label": "Main Arm Upper Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47460,
    "label": "Malanda State High School",
    "franchise": "CQ"
    },
    {
    "id": 47128,
    "label": "Malanda State School",
    "franchise": "CQ"
    },
    {
    "id": 47555,
    "label": "Maleny State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47125,
    "label": "Maleny State School",
    "franchise": "Brisbane"
    },
    {
    "id": 50483,
    "label": "Mango Hill State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52803,
    "label": "Mango Hill State Secondary College",
    "franchise": "Brisbane"
    },
    {
    "id": "42254",
    "label": "Manifold Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47068,
    "label": "Manly State School",
    "franchise": "SEQ"
    },
    {
    "id": 46592,
    "label": "Manly West State School",
    "franchise": "SEQ"
    },
    {
    "id": 47453,
    "label": "Mansfield State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46506,
    "label": "Mansfield State School",
    "franchise": "SEQ"
    },
    {
    "id": 46927,
    "label": "Mapleton State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46633,
    "label": "Marburg State School",
    "franchise": "SEQ"
    },
    {
    "id": 47452,
    "label": "Mareeba State High School",
    "franchise": "CQ"
    },
    {
    "id": 46833,
    "label": "Mareeba State School",
    "franchise": "CQ"
    },
    {
    "id": 47764,
    "label": "Marian Catholic School",
    "franchise": "CQ"
    },
    {
    "id": 46758,
    "label": "Marian State School",
    "franchise": "CQ"
    },
    {
    "id": 52895,
    "label": "Maridahdi Brisbane Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 48041,
    "label": "Maridahdi Early Childhood Community School",
    "franchise": "SEQ"
    },
    {
    "id": 47647,
    "label": "Marist College",
    "franchise": "CQ"
    },
    {
    "id": 47856,
    "label": "Marist College Ashgrove",
    "franchise": "Brisbane"
    },
    {
    "id": 47191,
    "label": "Marlborough State School",
    "franchise": "CQ"
    },
    {
    "id": 47027,
    "label": "Marmor State School",
    "franchise": "CQ"
    },
    {
    "id": 47482,
    "label": "Maroochydore State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47234,
    "label": "Maroochydore State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46830,
    "label": "Maroon State School",
    "franchise": "SEQ"
    },
    {
    "id": 47081,
    "label": "Maroondan State School",
    "franchise": "CQ"
    },
    {
    "id": 47554,
    "label": "Marsden State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47297,
    "label": "Marsden State School",
    "franchise": "SEQ"
    },
    {
    "id": 46858,
    "label": "Marshall Road State School",
    "franchise": "SEQ"
    },
    {
    "id": 47681,
    "label": "Mary Immaculate Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47666,
    "label": "Mary MacKillop Catholic College",
    "franchise": "SEQ"
    },
    {
    "id": 47869,
    "label": "Mary MacKillop College",
    "franchise": "Brisbane"
    },
    {
    "id": 46721,
    "label": "Mary Valley State College",
    "franchise": "Brisbane"
    },
    {
    "id": 46455,
    "label": "Maryborough Central State School",
    "franchise": "CQ"
    },
    {
    "id": 47592,
    "label": "Maryborough Special School",
    "franchise": "CQ"
    },
    {
    "id": 47421,
    "label": "Maryborough State High School",
    "franchise": "CQ"
    },
    {
    "id": 46738,
    "label": "Maryborough West State School",
    "franchise": "CQ"
    },
    {
    "id": 47889,
    "label": "Marymount College (Burleigh Waters)",
    "franchise": "GCNR"
    },
    {
    "id": 47745,
    "label": "Marymount Primary School (Burleigh Waters)",
    "franchise": "GCNR"
    },
    {
    "id": 47124,
    "label": "Maryvale State School",
    "franchise": "SEQ"
    },
    {
    "id": 52969,
    "label": "Mastery Schools Australia (Varsity Lakes)",
    "franchise": "GCNR"
    },
    {
    "id": 47682,
    "label": "Mater Dei Catholic Primary School (Ashgrove West)",
    "franchise": "Brisbane"
    },
    {
    "id": 47767,
    "label": "Mater Dei Primary School (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 48005,
    "label": "Matthew Flinders Anglican College",
    "franchise": "Brisbane"
    },
    {
    "id": 52897,
    "label": "Mawarra Independent School",
    "franchise": "Brisbane"
    },
    {
    "id": 47016,
    "label": "Mayfield State School",
    "franchise": "SEQ"
    },
    {
    "id": 52441,
    "label": "McAuley College",
    "franchise": "SEQ"
    },
    {
    "id": 47130,
    "label": "McDonnell Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46669,
    "label": "McDowall State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47127,
    "label": "McIlwraith State School",
    "franchise": "CQ"
    },
    {
    "id": 47150,
    "label": "Meandarra State School",
    "franchise": "NE"
    },
    {
    "id": 52866,
    "label": "Men of Business Academy",
    "franchise": "GCNR"
    },
    {
    "id": 47221,
    "label": "Mena Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 47903,
    "label": "Mercy College",
    "franchise": "CQ"
    },
    {
    "id": 47246,
    "label": "Meridan State College",
    "franchise": "Brisbane"
    },
    {
    "id": 46902,
    "label": "Merinda State School",
    "franchise": "CQ"
    },
    {
    "id": 46537,
    "label": "Meringandan State School",
    "franchise": "SEQ"
    },
    {
    "id": 47526,
    "label": "Merrimac State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47192,
    "label": "Merrimac State School",
    "franchise": "GCNR"
    },
    {
    "id": 47093,
    "label": "Miallo State School",
    "franchise": "CQ"
    },
    {
    "id": 47471,
    "label": "Miami State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47308,
    "label": "Miami State School",
    "franchise": "GCNR"
    },
    {
    "id": 47378,
    "label": "Middle Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 46691,
    "label": "Middle Ridge State School",
    "franchise": "SEQ"
    },
    {
    "id": 47320,
    "label": "Middlemount Community School",
    "franchise": "CQ"
    },
    {
    "id": 47523,
    "label": "Miles State High School",
    "franchise": "NE"
    },
    {
    "id": 46643,
    "label": "Miles State School",
    "franchise": "NE"
    },
    {
    "id": 46884,
    "label": "Millaa Millaa State School",
    "franchise": "CQ"
    },
    {
    "id": 46920,
    "label": "Millaroo State School",
    "franchise": "CQ"
    },
    {
    "id": 46517,
    "label": "Millchester State School",
    "franchise": "CQ"
    },
    {
    "id": 46534,
    "label": "Millmerran State School",
    "franchise": "SEQ"
    },
    {
    "id": 47025,
    "label": "Milman State School",
    "franchise": "CQ"
    },
    {
    "id": 47424,
    "label": "Milpera State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46780,
    "label": "Milton State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46613,
    "label": "Minden State School",
    "franchise": "SEQ"
    },
    {
    "id": 53049,
    "label": "Ming-De International School Toowoomba",
    "franchise": "SEQ"
    },
    {
    "id": 46593,
    "label": "Minimbah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47500,
    "label": "Mirani State High School",
    "franchise": "CQ"
    },
    {
    "id": 46834,
    "label": "Mirani State School",
    "franchise": "CQ"
    },
    {
    "id": 46897,
    "label": "Miriam Vale State School",
    "franchise": "CQ"
    },
    {
    "id": 47123,
    "label": "Mirriwinni State School",
    "franchise": "CQ"
    },
    {
    "id": 47106,
    "label": "Mission Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 47332,
    "label": "Mistake Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 46413,
    "label": "Mitchell State School",
    "franchise": "NE"
    },
    {
    "id": 47584,
    "label": "Mitchelton Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47435,
    "label": "Mitchelton State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47177,
    "label": "Mitchelton State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42258",
    "label": "Modanville Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46855,
    "label": "Moffatdale State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46456,
    "label": "Moggill State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46693,
    "label": "Monkland State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48042,
    "label": "Montessori International College (Forest Glen)",
    "franchise": "Brisbane"
    },
    {
    "id": 52444,
    "label": "Montessori Noosa (Sunshine Beach)",
    "franchise": "Brisbane"
    },
    {
    "id": 47483,
    "label": "Monto State High School",
    "franchise": "CQ"
    },
    {
    "id": 47274,
    "label": "Monto State School",
    "franchise": "CQ"
    },
    {
    "id": 46888,
    "label": "Montville State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47272,
    "label": "Mooloolaba State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46624,
    "label": "Mooloolah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46753,
    "label": "Moonie State School",
    "franchise": "NE"
    },
    {
    "id": 47248,
    "label": "Moore Park Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 47210,
    "label": "Moorooka State School",
    "franchise": "SEQ"
    },
    {
    "id": 47323,
    "label": "Moranbah East State School",
    "franchise": "CQ"
    },
    {
    "id": 47516,
    "label": "Moranbah State High School",
    "franchise": "CQ"
    },
    {
    "id": 46934,
    "label": "Moranbah State School",
    "franchise": "CQ"
    },
    {
    "id": 47392,
    "label": "Morayfield East State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47533,
    "label": "Morayfield State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46492,
    "label": "Morayfield State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47979,
    "label": "Moreton Bay Boys' College",
    "franchise": "SEQ"
    },
    {
    "id": 48045,
    "label": "Moreton Bay College",
    "franchise": "SEQ"
    },
    {
    "id": 47255,
    "label": "Moreton Downs State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47268,
    "label": "Morningside State School",
    "franchise": "SEQ"
    },
    {
    "id": 46610,
    "label": "Mornington Island State School",
    "franchise": "CQ"
    },
    {
    "id": 46765,
    "label": "Morven State School",
    "franchise": "NE"
    },
    {
    "id": 47407,
    "label": "Mossman State High School",
    "franchise": "CQ"
    },
    {
    "id": 46527,
    "label": "Mossman State School",
    "franchise": "CQ"
    },
    {
    "id": 47833,
    "label": "Mother of Good Counsel School",
    "franchise": "CQ"
    },
    {
    "id": 50391,
    "label": "Mother Teresa Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 46671,
    "label": "Mount Alford State School",
    "franchise": "SEQ"
    },
    {
    "id": 47872,
    "label": "Mount Alvernia College",
    "franchise": "Brisbane"
    },
    {
    "id": 47327,
    "label": "Mount Archer State School",
    "franchise": "CQ"
    },
    {
    "id": 46605,
    "label": "Mount Cotton State School",
    "franchise": "SEQ"
    },
    {
    "id": 46595,
    "label": "Mount Crosby State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47384,
    "label": "Mount Fox State School",
    "franchise": "CQ"
    },
    {
    "id": 46956,
    "label": "Mount Garnet State School",
    "franchise": "CQ"
    },
    {
    "id": 46835,
    "label": "Mount Gravatt East State School",
    "franchise": "SEQ"
    },
    {
    "id": 47450,
    "label": "Mount Gravatt State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46552,
    "label": "Mount Gravatt State School",
    "franchise": "SEQ"
    },
    {
    "id": 47010,
    "label": "Mount Isa Central State School",
    "franchise": "CQ"
    },
    {
    "id": 47934,
    "label": "Mount Isa Flexible Learning Centre",
    "franchise": "CQ"
    },
    {
    "id": 47624,
    "label": "Mount Isa School of the Air",
    "franchise": "CQ"
    },
    {
    "id": 47583,
    "label": "Mount Isa Special School",
    "franchise": "CQ"
    },
    {
    "id": 47046,
    "label": "Mount Kilcoy State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46619,
    "label": "Mount Larcom State School",
    "franchise": "CQ"
    },
    {
    "id": 47057,
    "label": "Mount Marrow State School",
    "franchise": "SEQ"
    },
    {
    "id": 46850,
    "label": "Mount Mee State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47019,
    "label": "Mount Molloy State School",
    "franchise": "CQ"
    },
    {
    "id": 46766,
    "label": "Mount Morgan Central State School",
    "franchise": "CQ"
    },
    {
    "id": 47415,
    "label": "Mount Morgan State High School",
    "franchise": "CQ"
    },
    {
    "id": 47165,
    "label": "Mount Murchison State School",
    "franchise": "CQ"
    },
    {
    "id": 46872,
    "label": "Mount Nebo State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47620,
    "label": "Mount Ommaney Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46458,
    "label": "Mount Perry State School",
    "franchise": "CQ"
    },
    {
    "id": 46668,
    "label": "Mount Samson State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47918,
    "label": "Mount St Bernard College",
    "franchise": "CQ"
    },
    {
    "id": "43541",
    "label": "Mount St Patrick College",
    "franchise": "GCNR"
    },
    {
    "id": "43526",
    "label": "Mount St Patrick Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47174,
    "label": "Mount Surprise State School",
    "franchise": "CQ"
    },
    {
    "id": 46720,
    "label": "Mount Sylvia State School",
    "franchise": "SEQ"
    },
    {
    "id": 47020,
    "label": "Mount Tarampa State School",
    "franchise": "SEQ"
    },
    {
    "id": 47000,
    "label": "Mount Tyson State School",
    "franchise": "SEQ"
    },
    {
    "id": 47321,
    "label": "Mount Warren Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 46744,
    "label": "Mount Whitestone State School",
    "franchise": "SEQ"
    },
    {
    "id": 47567,
    "label": "Mountain Creek State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47406,
    "label": "Mountain Creek State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47517,
    "label": "Moura State High School",
    "franchise": "CQ"
    },
    {
    "id": 46950,
    "label": "Moura State School",
    "franchise": "CQ"
    },
    {
    "id": 46964,
    "label": "Mourilyan State School",
    "franchise": "CQ"
    },
    {
    "id": 47671,
    "label": "Mt Maria College - Petrie",
    "franchise": "Brisbane"
    },
    {
    "id": 40388,
    "label": "Mt Maria College (Mitchelton)",
    "franchise": "Brisbane"
    },
    {
    "id": 47873,
    "label": "Mt St Michael's College",
    "franchise": "Brisbane"
    },
    {
    "id": 46519,
    "label": "Mudgeeraba Creek State School",
    "franchise": "GCNR"
    },
    {
    "id": 47608,
    "label": "Mudgeeraba State Special School",
    "franchise": "GCNR"
    },
    {
    "id": 46837,
    "label": "Mudgeeraba State School",
    "franchise": "GCNR"
    },
    {
    "id": 48061,
    "label": "Mueller College",
    "franchise": "Brisbane"
    },
    {
    "id": 47135,
    "label": "Mulgildie State School",
    "franchise": "CQ"
    },
    {
    "id": "42382",
    "label": "Mullumbimby High School",
    "franchise": "GCNR"
    },
    {
    "id": "42261",
    "label": "Mullumbimby Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42262",
    "label": "Mummulgum Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46699,
    "label": "Mundingburra State School",
    "franchise": "CQ"
    },
    {
    "id": 46867,
    "label": "Mundoo State School",
    "franchise": "CQ"
    },
    {
    "id": 47136,
    "label": "Mundubbera State School",
    "franchise": "CQ"
    },
    {
    "id": 40949,
    "label": "Mungalla Silver Lining School",
    "franchise": "CQ"
    },
    {
    "id": 46751,
    "label": "Mungallala State School",
    "franchise": "NE"
    },
    {
    "id": 46564,
    "label": "Mungar State School",
    "franchise": "CQ"
    },
    {
    "id": 47148,
    "label": "Murarrie State School",
    "franchise": "SEQ"
    },
    {
    "id": 47446,
    "label": "Murgon State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46924,
    "label": "Murgon State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46459,
    "label": "Murphy's Creek State School",
    "franchise": "SEQ"
    },
    {
    "id": 46998,
    "label": "Murray River Upper State School",
    "franchise": "CQ"
    },
    {
    "id": 47053,
    "label": "Murray's Bridge State School",
    "franchise": "SEQ"
    },
    {
    "id": 50481,
    "label": "Murrumba State Secondary College",
    "franchise": "Brisbane"
    },
    {
    "id": "42336",
    "label": "Murwillumbah East Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42376",
    "label": "Murwillumbah High School",
    "franchise": "GCNR"
    },
    {
    "id": "42263",
    "label": "Murwillumbah Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47225,
    "label": "Musgrave Hill State School",
    "franchise": "GCNR"
    },
    {
    "id": 40842,
    "label": "Music Industry College",
    "franchise": "Brisbane"
    },
    {
    "id": 47104,
    "label": "Mutarnee State School",
    "franchise": "CQ"
    },
    {
    "id": 46980,
    "label": "Mutchilba State School",
    "franchise": "CQ"
    },
    {
    "id": 46513,
    "label": "Mutdapilly State School",
    "franchise": "SEQ"
    },
    {
    "id": 46622,
    "label": "Muttaburra State School",
    "franchise": "CQ"
    },
    {
    "id": 52497,
    "label": "My Independent School",
    "franchise": "Brisbane"
    },
    {
    "id": 47156,
    "label": "Nagoorin State School",
    "franchise": "CQ"
    },
    {
    "id": 48107,
    "label": "Nambour Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47603,
    "label": "Nambour Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47429,
    "label": "Nambour State College",
    "franchise": "Brisbane"
    },
    {
    "id": 47536,
    "label": "Nanango State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46460,
    "label": "Nanango State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47062,
    "label": "Narangba State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46705,
    "label": "Narangba Valley State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47242,
    "label": "Narangba Valley State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47621,
    "label": "Narbethong State Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46734,
    "label": "Nashville State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46542,
    "label": "Nebo State School",
    "franchise": "CQ"
    },
    {
    "id": 47548,
    "label": "Nerang State High School",
    "franchise": "GCNR"
    },
    {
    "id": 46565,
    "label": "Nerang State School",
    "franchise": "GCNR"
    },
    {
    "id": 46955,
    "label": "New Farm State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46574,
    "label": "Newmarket State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42267",
    "label": "Newrybar Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47071,
    "label": "Newtown State School",
    "franchise": "SEQ"
    },
    {
    "id": "42268",
    "label": "Nimbin Central School",
    "franchise": "GCNR"
    },
    {
    "id": 47247,
    "label": "Nobby State School",
    "franchise": "SEQ"
    },
    {
    "id": 47986,
    "label": "Noosa Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47473,
    "label": "Noosa District State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47928,
    "label": "Noosa Flexible Learning Centre",
    "franchise": "Brisbane"
    },
    {
    "id": 47941,
    "label": "Noosa Pengari Steiner School",
    "franchise": "Brisbane"
    },
    {
    "id": 46533,
    "label": "Noosaville State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47249,
    "label": "Norfolk Village State School",
    "franchise": "GCNR"
    },
    {
    "id": 46948,
    "label": "Norman Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 46658,
    "label": "Normanton State School",
    "franchise": "CQ"
    },
    {
    "id": 47286,
    "label": "Norris Road State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46725,
    "label": "North Arm State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46869,
    "label": "North Eton State School",
    "franchise": "CQ"
    },
    {
    "id": 47574,
    "label": "North Lakes State College",
    "franchise": "Brisbane"
    },
    {
    "id": 47436,
    "label": "North Rockhampton State High School",
    "franchise": "CQ"
    },
    {
    "id": 52609,
    "label": "North Shore State School",
    "franchise": "CQ"
    },
    {
    "id": 46559,
    "label": "Northern Beaches State High School",
    "franchise": "CQ"
    },
    {
    "id": 47077,
    "label": "Northern Peninsula Area State College",
    "franchise": "CQ"
    },
    {
    "id": 46604,
    "label": "Northgate State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48016,
    "label": "Northpine Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 48051,
    "label": "Northside Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47367,
    "label": "Northview State School",
    "franchise": "CQ"
    },
    {
    "id": 46706,
    "label": "Norville State School",
    "franchise": "CQ"
    },
    {
    "id": 46893,
    "label": "Numinbah Valley State School",
    "franchise": "GCNR"
    },
    {
    "id": 46429,
    "label": "Nundah State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47617,
    "label": "Nursery Road State Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47073,
    "label": "Oakenden State School",
    "franchise": "CQ"
    },
    {
    "id": 47484,
    "label": "Oakey State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46526,
    "label": "Oakey State School",
    "franchise": "SEQ"
    },
    {
    "id": 46637,
    "label": "Oakleigh State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46820,
    "label": "Oakwood State School",
    "franchise": "CQ"
    },
    {
    "id": "42151",
    "label": "Ocean Shores Public School",
    "franchise": "GCNR"
    },
    {
    "id": 52464,
    "label": "Ohana College",
    "franchise": "SEQ"
    },
    {
    "id": "42271",
    "label": "Old Bonalbo Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46436,
    "label": "One Mile State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47970,
    "label": "OneSchool Global QLD",
    "franchise": "SEQ"
    },
    {
    "id": 52940,
    "label": "Ontrack College Emerald",
    "franchise": "CQ"
    },
    {
    "id": 46782,
    "label": "Oonoonba State School",
    "franchise": "CQ"
    },
    {
    "id": 47087,
    "label": "Orion State School",
    "franchise": "CQ"
    },
    {
    "id": 46640,
    "label": "Ormeau State School",
    "franchise": "GCNR"
    },
    {
    "id": 47643,
    "label": "Ormeau Woods State High School",
    "franchise": "GCNR"
    },
    {
    "id": 48088,
    "label": "Ormiston College",
    "franchise": "SEQ"
    },
    {
    "id": 46410,
    "label": "Ormiston State School",
    "franchise": "SEQ"
    },
    {
    "id": 46457,
    "label": "Osborne State School",
    "franchise": "CQ"
    },
    {
    "id": "43522",
    "label": "Our Lady Help of Christians Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47834,
    "label": "Our Lady Help of Christians School (Earlville)",
    "franchise": "CQ"
    },
    {
    "id": 47683,
    "label": "Our Lady Help of Christians School (Hendra)",
    "franchise": "Brisbane"
    },
    {
    "id": 47684,
    "label": "Our Lady of Dolours School",
    "franchise": "Brisbane"
    },
    {
    "id": 47685,
    "label": "Our Lady of Fatima Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47746,
    "label": "Our Lady of Good Counsel School",
    "franchise": "SEQ"
    },
    {
    "id": 47818,
    "label": "Our Lady of Lourdes Primary School (Ingham)",
    "franchise": "CQ"
    },
    {
    "id": 47686,
    "label": "Our Lady of Lourdes Primary School (Sunnybank)",
    "franchise": "SEQ"
    },
    {
    "id": 47768,
    "label": "Our Lady of Lourdes Primary School (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47687,
    "label": "Our Lady of Mount Carmel School",
    "franchise": "SEQ"
    },
    {
    "id": 47688,
    "label": "Our Lady of the Angels' School",
    "franchise": "Brisbane"
    },
    {
    "id": 47689,
    "label": "Our Lady of the Assumption School",
    "franchise": "Brisbane"
    },
    {
    "id": 47762,
    "label": "Our Lady of the Rosary School (Caloundra)",
    "franchise": "Brisbane"
    },
    {
    "id": 47690,
    "label": "Our Lady of the Rosary School (Kenmore)",
    "franchise": "Brisbane"
    },
    {
    "id": 47691,
    "label": "Our Lady of the Sacred Heart Catholic Primary School (Darra)",
    "franchise": "SEQ"
    },
    {
    "id": 47791,
    "label": "Our Lady of the Sacred Heart Catholic Primary School (Springsure)",
    "franchise": "CQ"
    },
    {
    "id": 47835,
    "label": "Our Lady of the Sacred Heart School Waiben",
    "franchise": "CQ"
    },
    {
    "id": 47790,
    "label": "Our Lady of the Southern Cross College",
    "franchise": "SEQ"
    },
    {
    "id": 47692,
    "label": "Our Lady of the Way School",
    "franchise": "Brisbane"
    },
    {
    "id": 47904,
    "label": "Our Lady's Catholic Primary School (Longreach)",
    "franchise": "CQ"
    },
    {
    "id": 47874,
    "label": "Our Lady's College (Annerley)",
    "franchise": "SEQ"
    },
    {
    "id": 47379,
    "label": "Oxenford State School",
    "franchise": "GCNR"
    },
    {
    "id": 46462,
    "label": "Oxley State School",
    "franchise": "SEQ"
    },
    {
    "id": "40827",
    "label": "Pacific Coast Christian School",
    "franchise": "GCNR"
    },
    {
    "id": 47964,
    "label": "Pacific Lutheran College",
    "franchise": "Brisbane"
    },
    {
    "id": 47398,
    "label": "Pacific Paradise State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47251,
    "label": "Pacific Pines State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47250,
    "label": "Pacific Pines State School",
    "franchise": "GCNR"
    },
    {
    "id": 47851,
    "label": "Padua College",
    "franchise": "Brisbane"
    },
    {
    "id": 46910,
    "label": "Pallara State School",
    "franchise": "SEQ"
    },
    {
    "id": 46583,
    "label": "Palm Beach State School",
    "franchise": "GCNR"
    },
    {
    "id": 47510,
    "label": "Palm Beach-Currumbin State High School",
    "franchise": "GCNR"
    },
    {
    "id": 52887,
    "label": "Palmview State Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 52889,
    "label": "Palmview State Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 46802,
    "label": "Palmwoods State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46977,
    "label": "Park Avenue State School",
    "franchise": "CQ"
    },
    {
    "id": 47639,
    "label": "Park Lake State School",
    "franchise": "GCNR"
    },
    {
    "id": 47564,
    "label": "Park Ridge State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46756,
    "label": "Park Ridge State School",
    "franchise": "SEQ"
    },
    {
    "id": 47147,
    "label": "Parke State School",
    "franchise": "CQ"
    },
    {
    "id": 46945,
    "label": "Parkhurst State School",
    "franchise": "CQ"
    },
    {
    "id": 47963,
    "label": "Parklands Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 46829,
    "label": "Parramatta State School",
    "franchise": "CQ"
    },
    {
    "id": 47158,
    "label": "Patrick Estate State School",
    "franchise": "SEQ"
    },
    {
    "id": 47287,
    "label": "Patricks Road State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46607,
    "label": "Payne Road State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48082,
    "label": "Peace Lutheran College",
    "franchise": "CQ"
    },
    {
    "id": 48073,
    "label": "Peace Lutheran Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 46842,
    "label": "Peachester State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46467,
    "label": "Peak Crossing State School",
    "franchise": "SEQ"
    },
    {
    "id": 46723,
    "label": "Pentland State School",
    "franchise": "CQ"
    },
    {
    "id": 47966,
    "label": "Peregian Beach College",
    "franchise": "Brisbane"
    },
    {
    "id": 47235,
    "label": "Peregian Springs State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46541,
    "label": "Petrie State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46463,
    "label": "Petrie Terrace State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46694,
    "label": "Pialba State School",
    "franchise": "CQ"
    },
    {
    "id": 52610,
    "label": "Picnic Creek State School",
    "franchise": "GCNR"
    },
    {
    "id": 46696,
    "label": "Pilton State School",
    "franchise": "SEQ"
    },
    {
    "id": 47443,
    "label": "Pimlico State High School",
    "franchise": "CQ"
    },
    {
    "id": 51492,
    "label": "Pimpama State Primary College",
    "franchise": "GCNR"
    },
    {
    "id": 46464,
    "label": "Pimpama State School",
    "franchise": "GCNR"
    },
    {
    "id": 50536,
    "label": "Pimpama State Secondary College",
    "franchise": "GCNR"
    },
    {
    "id": 47133,
    "label": "Pindi Pindi State School",
    "franchise": "CQ"
    },
    {
    "id": 48087,
    "label": "Pine Community School",
    "franchise": "Brisbane"
    },
    {
    "id": 47588,
    "label": "Pine Rivers Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47487,
    "label": "Pine Rivers State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 52898,
    "label": "Pinnacle Academic College",
    "franchise": "Brisbane"
    },
    {
    "id": 47035,
    "label": "Pinnacle State School",
    "franchise": "CQ"
    },
    {
    "id": 47547,
    "label": "Pioneer State High School",
    "franchise": "CQ"
    },
    {
    "id": 47502,
    "label": "Pittsworth State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46667,
    "label": "Pittsworth State School",
    "franchise": "SEQ"
    },
    {
    "id": 46865,
    "label": "Pomona State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46714,
    "label": "Pormpuraaw State School",
    "franchise": "CQ"
    },
    {
    "id": 46558,
    "label": "Port Curtis Road State School",
    "franchise": "CQ"
    },
    {
    "id": 47387,
    "label": "Port Douglas State School",
    "franchise": "CQ"
    },
    {
    "id": "42324",
    "label": "Pottsville Beach Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47232,
    "label": "Pozieres State School",
    "franchise": "SEQ"
    },
    {
    "id": 46740,
    "label": "Prairie State School",
    "franchise": "CQ"
    },
    {
    "id": 46582,
    "label": "Prenzlau State School",
    "franchise": "SEQ"
    },
    {
    "id": 48077,
    "label": "Prince of Peace Lutheran College",
    "franchise": "Brisbane"
    },
    {
    "id": 47475,
    "label": "Proserpine State High School",
    "franchise": "CQ"
    },
    {
    "id": 46890,
    "label": "Proserpine State School",
    "franchise": "CQ"
    },
    {
    "id": 47212,
    "label": "Prospect Creek State School",
    "franchise": "CQ"
    },
    {
    "id": 47042,
    "label": "Proston State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46503,
    "label": "Pullenvale State School",
    "franchise": "Brisbane"
    },
    {
    "id": 52459,
    "label": "Pumicestone State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47693,
    "label": "Queen of Apostles Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 47060,
    "label": "Queens Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 40787,
    "label": "Queensland Academy for Creative Industries",
    "franchise": "Brisbane"
    },
    {
    "id": 40815,
    "label": "Queensland Academy for Health Sciences",
    "franchise": "GCNR"
    },
    {
    "id": 40764,
    "label": "Queensland Academy for Science Mathematics and Technology",
    "franchise": "Brisbane"
    },
    {
    "id": 40771,
    "label": "Queensland Children's Hospital School",
    "franchise": "SEQ"
    },
    {
    "id": 47601,
    "label": "Queensland Pathways State College",
    "franchise": "SEQ"
    },
    {
    "id": 47196,
    "label": "Quilpie State College",
    "franchise": "NE"
    },
    {
    "id": 46965,
    "label": "Quinalow Prep-10 State School",
    "franchise": "SEQ"
    },
    {
    "id": 46972,
    "label": "Raceview State School",
    "franchise": "SEQ"
    },
    {
    "id": 50702,
    "label": "Radiant Life College",
    "franchise": "CQ"
    },
    {
    "id": 47173,
    "label": "Railway Estate State School",
    "franchise": "CQ"
    },
    {
    "id": 47365,
    "label": "Rainbow Beach State School",
    "franchise": "Brisbane"
    },
    {
    "id": "43810",
    "label": "Rainbow Ridge School for Steiner Education",
    "franchise": "GCNR"
    },
    {
    "id": 47161,
    "label": "Rainworth State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46415,
    "label": "Ramsay State School",
    "franchise": "SEQ"
    },
    {
    "id": 47051,
    "label": "Rangeville State School",
    "franchise": "SEQ"
    },
    {
    "id": "42279",
    "label": "Rappville Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47293,
    "label": "Rasmussen State School",
    "franchise": "CQ"
    },
    {
    "id": 47109,
    "label": "Rathdowney State School",
    "franchise": "SEQ"
    },
    {
    "id": 47103,
    "label": "Ravenshoe State School",
    "franchise": "CQ"
    },
    {
    "id": 46504,
    "label": "Ravenswood State School",
    "franchise": "CQ"
    },
    {
    "id": 47618,
    "label": "Red Hill Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47552,
    "label": "Redbank Plains State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46469,
    "label": "Redbank Plains State School",
    "franchise": "SEQ"
    },
    {
    "id": 46864,
    "label": "Redbank State School",
    "franchise": "SEQ"
    },
    {
    "id": 47586,
    "label": "Redcliffe Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47439,
    "label": "Redcliffe State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 48075,
    "label": "Redeemer Lutheran College (Biloela)",
    "franchise": "CQ"
    },
    {
    "id": 48084,
    "label": "Redeemer Lutheran College (Rochedale)",
    "franchise": "SEQ"
    },
    {
    "id": 46575,
    "label": "Redland Bay State School",
    "franchise": "SEQ"
    },
    {
    "id": 47599,
    "label": "Redland District Special School",
    "franchise": "SEQ"
    },
    {
    "id": 48092,
    "label": "Redlands College",
    "franchise": "SEQ"
    },
    {
    "id": 47110,
    "label": "Redlynch State College",
    "franchise": "CQ"
    },
    {
    "id": 52463,
    "label": "Redwood College",
    "franchise": "Brisbane"
    },
    {
    "id": 47403,
    "label": "Regents Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 46866,
    "label": "Richlands East State School",
    "franchise": "SEQ"
    },
    {
    "id": "43903",
    "label": "Richmond Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 46870,
    "label": "Richmond Hill State School",
    "franchise": "CQ"
    },
    {
    "id": 46791,
    "label": "Richmond State School",
    "franchise": "CQ"
    },
    {
    "id": 47160,
    "label": "Ridgelands State School",
    "franchise": "CQ"
    },
    {
    "id": 52806,
    "label": "Ripley Valley State School",
    "franchise": "SEQ"
    },
    {
    "id": 52808,
    "label": "Ripley Valley State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 48063,
    "label": "Rivermount College",
    "franchise": "GCNR"
    },
    {
    "id": 48022,
    "label": "Riverside Adventist School",
    "franchise": "CQ"
    },
    {
    "id": 48067,
    "label": "Riverside Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47288,
    "label": "Riverview State School",
    "franchise": "SEQ"
    },
    {
    "id": 46795,
    "label": "Roadvale State School",
    "franchise": "SEQ"
    },
    {
    "id": 47314,
    "label": "Robertson State School",
    "franchise": "SEQ"
    },
    {
    "id": 46550,
    "label": "Robina State High School",
    "franchise": "GCNR"
    },
    {
    "id": 47390,
    "label": "Robina State School",
    "franchise": "GCNR"
    },
    {
    "id": 47304,
    "label": "Rochedale South State School",
    "franchise": "SEQ"
    },
    {
    "id": 47537,
    "label": "Rochedale State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47211,
    "label": "Rochedale State School",
    "franchise": "SEQ"
    },
    {
    "id": 51500,
    "label": "Rockhampton Flexible Learning Centre",
    "franchise": "CQ"
    },
    {
    "id": 48031,
    "label": "Rockhampton Girls Grammar School",
    "franchise": "CQ"
    },
    {
    "id": 47602,
    "label": "Rockhampton North Special School",
    "franchise": "CQ"
    },
    {
    "id": 47587,
    "label": "Rockhampton Special School",
    "franchise": "CQ"
    },
    {
    "id": 47416,
    "label": "Rockhampton State High School",
    "franchise": "CQ"
    },
    {
    "id": 46711,
    "label": "Rocklea State School",
    "franchise": "SEQ"
    },
    {
    "id": 47265,
    "label": "Rockville State School",
    "franchise": "SEQ"
    },
    {
    "id": 46470,
    "label": "Rolleston State School",
    "franchise": "CQ"
    },
    {
    "id": 47107,
    "label": "Rollingstone State School",
    "franchise": "CQ"
    },
    {
    "id": 40767,
    "label": "Roma State College",
    "franchise": "NE"
    },
    {
    "id": 46815,
    "label": "Ropeley State School",
    "franchise": "SEQ"
    },
    {
    "id": "42284",
    "label": "Rosebank Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46880,
    "label": "Rosedale State School",
    "franchise": "CQ"
    },
    {
    "id": 47581,
    "label": "Rosella Park School",
    "franchise": "CQ"
    },
    {
    "id": 47530,
    "label": "Rosewood State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46498,
    "label": "Rosewood State School",
    "franchise": "SEQ"
    },
    {
    "id": 47385,
    "label": "Rossville State School",
    "franchise": "CQ"
    },
    {
    "id": "42285",
    "label": "Rous Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46728,
    "label": "Runcorn Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47546,
    "label": "Runcorn State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46971,
    "label": "Runcorn State School",
    "franchise": "SEQ"
    },
    {
    "id": 47159,
    "label": "Russell Island State School",
    "franchise": "SEQ"
    },
    {
    "id": 47865,
    "label": "Ryan Catholic College",
    "franchise": "CQ"
    },
    {
    "id": 47792,
    "label": "Sacred Heart Catholic Primary School (Yeppoon)",
    "franchise": "CQ"
    },
    {
    "id": 47769,
    "label": "Sacred Heart Primary School (Cunnamulla)",
    "franchise": "NE"
    },
    {
    "id": 47695,
    "label": "Sacred Heart Primary School (Sandgate)",
    "franchise": "Brisbane"
    },
    {
    "id": 47770,
    "label": "Sacred Heart Primary School (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47694,
    "label": "Sacred Heart School (Booval)",
    "franchise": "SEQ"
    },
    {
    "id": 47940,
    "label": "Saint Stephen's College (Coomera)",
    "franchise": "GCNR"
    },
    {
    "id": 47229,
    "label": "Salisbury State School",
    "franchise": "SEQ"
    },
    {
    "id": 46471,
    "label": "Samford State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48095,
    "label": "Samford Valley Steiner School",
    "franchise": "Brisbane"
    },
    {
    "id": 52760,
    "label": "San Damiano College",
    "franchise": "SEQ"
    },
    {
    "id": 47875,
    "label": "San Sisto College",
    "franchise": "SEQ"
    },
    {
    "id": 47441,
    "label": "Sandgate District State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46514,
    "label": "Sandgate State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47256,
    "label": "Sandy Strait State School",
    "franchise": "CQ"
    },
    {
    "id": 47486,
    "label": "Sarina State High School",
    "franchise": "CQ"
    },
    {
    "id": 46778,
    "label": "Sarina State School",
    "franchise": "CQ"
    },
    {
    "id": "44027",
    "label": "Sathya Sai College",
    "franchise": "GCNR"
    },
    {
    "id": "52419",
    "label": "Sathya Sai College Secondary Campus, Dungay",
    "franchise": "GCNR"
    },
    {
    "id": 47185,
    "label": "Scarborough State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46731,
    "label": "Scottville State School",
    "franchise": "CQ"
    },
    {
    "id": 47154,
    "label": "Seaforth State School",
    "franchise": "CQ"
    },
    {
    "id": 46800,
    "label": "Serviceton South State School",
    "franchise": "SEQ"
    },
    {
    "id": 47876,
    "label": "Seton College",
    "franchise": "SEQ"
    },
    {
    "id": 46996,
    "label": "Seven Hills State School",
    "franchise": "SEQ"
    },
    {
    "id": 46987,
    "label": "Severnlea State School",
    "franchise": "SEQ"
    },
    {
    "id": 47031,
    "label": "Seville Road State School",
    "franchise": "SEQ"
    },
    {
    "id": 47528,
    "label": "Shailer Park State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47329,
    "label": "Shailer Park State School",
    "franchise": "SEQ"
    },
    {
    "id": 47900,
    "label": "Shalom College",
    "franchise": "CQ"
    },
    {
    "id": 46683,
    "label": "Sharon State School",
    "franchise": "CQ"
    },
    {
    "id": "43760",
    "label": "Shearwater, the Mullumbimby Steiner School",
    "franchise": "GCNR"
    },
    {
    "id": 47947,
    "label": "Sheldon College",
    "franchise": "SEQ"
    },
    {
    "id": 46461,
    "label": "Sherwood State School",
    "franchise": "SEQ"
    },
    {
    "id": 47205,
    "label": "Shorncliffe State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47651,
    "label": "Siena Catholic College",
    "franchise": "Brisbane"
    },
    {
    "id": 47659,
    "label": "Siena Catholic Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 46589,
    "label": "Silkstone State School",
    "franchise": "SEQ"
    },
    {
    "id": 47950,
    "label": "Silkwood School (Mount Nathan)",
    "franchise": "GCNR"
    },
    {
    "id": 47172,
    "label": "Silkwood State School (Silkwood)",
    "franchise": "CQ"
    },
    {
    "id": 53023,
    "label": "Silver Lining School (Barcaldine)",
    "franchise": "CQ"
    },
    {
    "id": 52826,
    "label": "Silver Lining School (Ficks Crossing)",
    "franchise": "Brisbane"
    },
    {
    "id": 48058,
    "label": "Sinai College",
    "franchise": "SEQ"
    },
    {
    "id": 47280,
    "label": "Slade Point State School",
    "franchise": "CQ"
    },
    {
    "id": 48093,
    "label": "Somerset College",
    "franchise": "GCNR"
    },
    {
    "id": 46851,
    "label": "Somerset Hills State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48014,
    "label": "Somerville House",
    "franchise": "SEQ"
    },
    {
    "id": 52744,
    "label": "Sophia College",
    "franchise": "SEQ"
    },
    {
    "id": 47169,
    "label": "South Johnstone State School",
    "franchise": "CQ"
    },
    {
    "id": 47075,
    "label": "Southbrook Central State School",
    "franchise": "SEQ"
    },
    {
    "id": 47653,
    "label": "Southern Cross Catholic College (Annandale)",
    "franchise": "CQ"
    },
    {
    "id": 47645,
    "label": "Southern Cross Catholic College (Scarborough)",
    "franchise": "Brisbane"
    },
    {
    "id": "42345",
    "label": "Southern Cross Public School",
    "franchise": "GCNR"
    },
    {
    "id": "52670",
    "label": "Southern Cross School of Distance Education",
    "franchise": "GCNR"
    },
    {
    "id": 52456,
    "label": "Southport Flexible Learning Centre",
    "franchise": "GCNR"
    },
    {
    "id": 47595,
    "label": "Southport Special School",
    "franchise": "GCNR"
    },
    {
    "id": 47432,
    "label": "Southport State High School",
    "franchise": "GCNR"
    },
    {
    "id": 46662,
    "label": "Southport State School",
    "franchise": "GCNR"
    },
    {
    "id": 40766,
    "label": "Spinifex State College - Mount Isa Education and Training Precinct",
    "franchise": "CQ"
    },
    {
    "id": 52709,
    "label": "Spring Mountain State School",
    "franchise": "SEQ"
    },
    {
    "id": 47344,
    "label": "Springbrook State School",
    "franchise": "GCNR"
    },
    {
    "id": 50364,
    "label": "Springfield Central State High School",
    "franchise": "SEQ"
    },
    {
    "id": 50365,
    "label": "Springfield Central State School",
    "franchise": "SEQ"
    },
    {
    "id": 47635,
    "label": "Springfield Lakes State School",
    "franchise": "SEQ"
    },
    {
    "id": 46475,
    "label": "Springsure State School",
    "franchise": "CQ"
    },
    {
    "id": 47292,
    "label": "Springwood Central State School",
    "franchise": "SEQ"
    },
    {
    "id": 46656,
    "label": "Springwood Road State School",
    "franchise": "SEQ"
    },
    {
    "id": 47520,
    "label": "Springwood State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47696,
    "label": "St Agatha's Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 47697,
    "label": "St Agnes School",
    "franchise": "SEQ"
    },
    {
    "id": 47998,
    "label": "St Aidan's Anglican Girls School",
    "franchise": "SEQ"
    },
    {
    "id": "43388",
    "label": "St Ambrose Catholic Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47698,
    "label": "St Ambrose's Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 47980,
    "label": "St Andrew's Anglican College",
    "franchise": "Brisbane"
    },
    {
    "id": 47657,
    "label": "St Andrew's Catholic College Redlynch Valley",
    "franchise": "CQ"
    },
    {
    "id": 47742,
    "label": "St Andrew's Catholic School (Ferny Grove)",
    "franchise": "Brisbane"
    },
    {
    "id": 48081,
    "label": "St Andrews Lutheran College (Tallebudgera)",
    "franchise": "GCNR"
    },
    {
    "id": 47794,
    "label": "St Anne's Catholic Primary School (Sarina)",
    "franchise": "CQ"
    },
    {
    "id": 52689,
    "label": "St Ann's School (Redbank Plains)",
    "franchise": "SEQ"
    },
    {
    "id": 40931,
    "label": "St Anthony's Catholic College (Deeragun)",
    "franchise": "CQ"
    },
    {
    "id": 47795,
    "label": "St Anthony's Catholic Primary School (North Rockhampton)",
    "franchise": "CQ"
    },
    {
    "id": "43167",
    "label": "St Anthony's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47741,
    "label": "St Anthony's School (Alexandra Hills)",
    "franchise": "SEQ"
    },
    {
    "id": 47836,
    "label": "St Anthony's School (Dimbulah)",
    "franchise": "CQ"
    },
    {
    "id": 47699,
    "label": "St Anthony's School (Kedron)",
    "franchise": "Brisbane"
    },
    {
    "id": 47771,
    "label": "St Anthony's School (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47669,
    "label": "St Augustine's College (Augustine Heights)",
    "franchise": "SEQ"
    },
    {
    "id": 47866,
    "label": "St Augustine's College (Cairns)",
    "franchise": "CQ"
    },
    {
    "id": 47747,
    "label": "St Augustine's Primary School (Currumbin Waters)",
    "franchise": "GCNR"
    },
    {
    "id": 47837,
    "label": "St Augustine's School (Mossman)",
    "franchise": "CQ"
    },
    {
    "id": 47932,
    "label": "St Benedict's Catholic Primary School (Yeppoon)",
    "franchise": "CQ"
    },
    {
    "id": 52397,
    "label": "St Benedict's Catholic School (Shaw)",
    "franchise": "CQ"
    },
    {
    "id": 50583,
    "label": "St Benedict's College (Mango Hill)",
    "franchise": "Brisbane"
    },
    {
    "id": 47930,
    "label": "St Benedict's Primary School (Mango Hill)",
    "franchise": "Brisbane"
    },
    {
    "id": 47138,
    "label": "St Bernard State School (Tamborine Mountain)",
    "franchise": "SEQ"
    },
    {
    "id": 47704,
    "label": "St Bernardine's School (Regents Park)",
    "franchise": "SEQ"
    },
    {
    "id": 47700,
    "label": "St Bernard's School (Upper Mount Gravatt)",
    "franchise": "SEQ"
    },
    {
    "id": 50699,
    "label": "St Brendan's Catholic Primary School (Mackay)",
    "franchise": "CQ"
    },
    {
    "id": 47905,
    "label": "St Brendan's College (Yeppoon)",
    "franchise": "CQ"
    },
    {
    "id": 47701,
    "label": "St Brendan's Primary School (Moorooka)",
    "franchise": "SEQ"
    },
    {
    "id": "43561",
    "label": "St Brigid's Catholic Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 50707,
    "label": "St Brigid's Catholic Primary School (Emerald)",
    "franchise": "CQ"
    },
    {
    "id": 47922,
    "label": "St Brigid's Catholic Primary School (Nerang)",
    "franchise": "GCNR"
    },
    {
    "id": "43313",
    "label": "St Brigid's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47748,
    "label": "St Brigid's Primary School (Rosewood)",
    "franchise": "SEQ"
    },
    {
    "id": "43521",
    "label": "St Carthage's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47819,
    "label": "St Catherine's Catholic College The Whitsundays (Proserpine)",
    "franchise": "CQ"
    },
    {
    "id": 47702,
    "label": "St Catherine's Catholic Primary School (Wishart)",
    "franchise": "SEQ"
    },
    {
    "id": 50375,
    "label": "St Clare's Catholic School (Burdell)",
    "franchise": "CQ"
    },
    {
    "id": 52442,
    "label": "St Clare's Primary School (Yarrabilba)",
    "franchise": "SEQ"
    },
    {
    "id": 47838,
    "label": "St Clare's School (Tully)",
    "franchise": "CQ"
    },
    {
    "id": 47820,
    "label": "St Colman's School (Home Hill)",
    "franchise": "CQ"
    },
    {
    "id": 47852,
    "label": "St Columban's College (Caboolture)",
    "franchise": "Brisbane"
    },
    {
    "id": 47703,
    "label": "St Columba's Primary School (Wilston)",
    "franchise": "Brisbane"
    },
    {
    "id": 47705,
    "label": "St Dympna's Parish School (Aspley)",
    "franchise": "Brisbane"
    },
    {
    "id": 47878,
    "label": "St Edmund's College (Woodend)",
    "franchise": "SEQ"
    },
    {
    "id": 47673,
    "label": "St Edward the Confessor School",
    "franchise": "SEQ"
    },
    {
    "id": 47706,
    "label": "St Elizabeth's School",
    "franchise": "SEQ"
    },
    {
    "id": 47912,
    "label": "St Eugene College",
    "franchise": "Brisbane"
    },
    {
    "id": "43510",
    "label": "St Finbarr's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47707,
    "label": "St Finbarr's School (Ashgrove)",
    "franchise": "Brisbane"
    },
    {
    "id": 47773,
    "label": "St Finbarr's School (Quilpie)",
    "franchise": "NE"
    },
    {
    "id": 47708,
    "label": "St Flannan's School",
    "franchise": "Brisbane"
    },
    {
    "id": 47897,
    "label": "St Francis Catholic Primary School (Tannum Sands)",
    "franchise": "CQ"
    },
    {
    "id": 47821,
    "label": "St Francis Catholic School (Hughenden)",
    "franchise": "CQ"
    },
    {
    "id": 47772,
    "label": "St Francis College (Crestmead)",
    "franchise": "SEQ"
    },
    {
    "id": 47774,
    "label": "St Francis De Sales School (Clifton)",
    "franchise": "SEQ"
    },
    {
    "id": 47822,
    "label": "St Francis School (Ayr)",
    "franchise": "CQ"
    },
    {
    "id": 47796,
    "label": "St Francis Xavier Catholic Primary School (Mackay West)",
    "franchise": "CQ"
    },
    {
    "id": "43506",
    "label": "St Francis Xavier Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47709,
    "label": "St Francis Xavier School (Goodna)",
    "franchise": "SEQ"
    },
    {
    "id": 47749,
    "label": "St Francis Xavier Catholic Primary School (Runaway Bay)",
    "franchise": "GCNR"
    },
    {
    "id": 47839,
    "label": "St Francis Xavier's School (Manunda)",
    "franchise": "CQ"
    },
    {
    "id": 47522,
    "label": "St George State High School",
    "franchise": "NE"
    },
    {
    "id": 46500,
    "label": "St George State School",
    "franchise": "NE"
    },
    {
    "id": 47710,
    "label": "St Gerard Majella Primary School",
    "franchise": "CQ"
    },
    {
    "id": 46580,
    "label": "St Helens State School",
    "franchise": "CQ"
    },
    {
    "id": 48007,
    "label": "St Hilda's School",
    "franchise": "GCNR"
    },
    {
    "id": 47711,
    "label": "St Ignatius School",
    "franchise": "Brisbane"
    },
    {
    "id": 47712,
    "label": "St Ita's Regional Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47853,
    "label": "St James College (Brisbane)",
    "franchise": "Brisbane"
    },
    {
    "id": 47987,
    "label": "St James Lutheran College (Urraween)",
    "franchise": "CQ"
    },
    {
    "id": "43537",
    "label": "St James' Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47713,
    "label": "St James Primary School (Coorparoo)",
    "franchise": "SEQ"
    },
    {
    "id": 47714,
    "label": "St Joachim's School",
    "franchise": "SEQ"
    },
    {
    "id": 47823,
    "label": "St John Bosco Catholic School",
    "franchise": "CQ"
    },
    {
    "id": 47868,
    "label": "St John Fisher College",
    "franchise": "Brisbane"
    },
    {
    "id": 47798,
    "label": "St John the Baptist Catholic Primary School (Gladstone)",
    "franchise": "CQ"
    },
    {
    "id": 47715,
    "label": "St John Vianney's Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 48009,
    "label": "St John's Anglican College (Forest Lake)",
    "franchise": "SEQ"
    },
    {
    "id": 47797,
    "label": "St John's Catholic Primary School (Walkerston)",
    "franchise": "CQ"
    },
    {
    "id": "43542",
    "label": "St John's College",
    "franchise": "GCNR"
    },
    {
    "id": 47753,
    "label": "St John's College (Nambour)",
    "franchise": "Brisbane"
    },
    {
    "id": 48076,
    "label": "St John's Lutheran Primary School (Bundaberg)",
    "franchise": "CQ"
    },
    {
    "id": 48080,
    "label": "St John's Lutheran School (Kingaroy)",
    "franchise": "Brisbane"
    },
    {
    "id": "43193",
    "label": "St John's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47862,
    "label": "St John's School (Roma)",
    "franchise": "NE"
    },
    {
    "id": 47840,
    "label": "St John's School (Silkwood)",
    "franchise": "CQ"
    },
    {
    "id": 47799,
    "label": "St Joseph's Catholic Primary School (Barcaldine)",
    "franchise": "CQ"
    },
    {
    "id": 47800,
    "label": "St Joseph's Catholic Primary School (Biloela)",
    "franchise": "CQ"
    },
    {
    "id": 47801,
    "label": "St Joseph's Catholic Primary School (Blackall)",
    "franchise": "CQ"
    },
    {
    "id": 47723,
    "label": "St Joseph's Catholic Primary School (Bracken Ridge)",
    "franchise": "Brisbane"
    },
    {
    "id": 47802,
    "label": "St Joseph's Catholic Primary School (Bundaberg)",
    "franchise": "CQ"
    },
    {
    "id": 47803,
    "label": "St Joseph's Catholic Primary School (Clermont)",
    "franchise": "CQ"
    },
    {
    "id": 47804,
    "label": "St Joseph's Catholic Primary School (North Mackay)",
    "franchise": "CQ"
    },
    {
    "id": 47805,
    "label": "St Joseph's Catholic Primary School (Park Avenue)",
    "franchise": "CQ"
    },
    {
    "id": 47806,
    "label": "St Joseph's Catholic Primary School (Wandal)",
    "franchise": "CQ"
    },
    {
    "id": 47824,
    "label": "St Joseph's Catholic School (Cloncurry)",
    "franchise": "CQ"
    },
    {
    "id": 47826,
    "label": "St Joseph's Catholic School (Mount Isa)",
    "franchise": "CQ"
    },
    {
    "id": 47825,
    "label": "St Joseph's Catholic School (Mundingburra)",
    "franchise": "CQ"
    },
    {
    "id": 47827,
    "label": "St Joseph's Catholic School, The Strand",
    "franchise": "CQ"
    },
    {
    "id": "43317",
    "label": "St Joseph's College",
    "franchise": "GCNR"
    },
    {
    "id": 47854,
    "label": "St Joseph's College (Brisbane)",
    "franchise": "Brisbane"
    },
    {
    "id": 52499,
    "label": "St Joseph's College (Coomera)",
    "franchise": "GCNR"
    },
    {
    "id": 47861,
    "label": "St Joseph's College (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47879,
    "label": "St Joseph's Nudgee College",
    "franchise": "Brisbane"
    },
    {
    "id": 51503,
    "label": "St Joseph's Parish School (Weipa)",
    "franchise": "CQ"
    },
    {
    "id": "43605",
    "label": "St Joseph's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47717,
    "label": "St Joseph's Primary School (Corinda)",
    "franchise": "SEQ"
    },
    {
    "id": 47718,
    "label": "St Joseph's Primary School (Kangaroo Point)",
    "franchise": "SEQ"
    },
    {
    "id": 47890,
    "label": "St Joseph's Primary School (Nambour)",
    "franchise": "Brisbane"
    },
    {
    "id": 47841,
    "label": "St Joseph's School (Atherton)",
    "franchise": "CQ"
    },
    {
    "id": 47716,
    "label": "St Joseph's School (Bardon)",
    "franchise": "Brisbane"
    },
    {
    "id": 47750,
    "label": "St Joseph's School (Childers)",
    "franchise": "CQ"
    },
    {
    "id": 47775,
    "label": "St Joseph's School (Chinchilla)",
    "franchise": "NE"
    },
    {
    "id": 47751,
    "label": "St Joseph's School (Gayndah)",
    "franchise": "CQ"
    },
    {
    "id": 47776,
    "label": "St Joseph's School (Millmerran)",
    "franchise": "SEQ"
    },
    {
    "id": 47752,
    "label": "St Joseph's School (Murgon)",
    "franchise": "Brisbane"
    },
    {
    "id": 47719,
    "label": "St Joseph's School (North Ipswich)",
    "franchise": "SEQ"
    },
    {
    "id": 47721,
    "label": "St Joseph's School (Nundah)",
    "franchise": "Brisbane"
    },
    {
    "id": 47842,
    "label": "St Joseph's School (Parramatta Park)",
    "franchise": "CQ"
    },
    {
    "id": 47896,
    "label": "St Joseph's School (Stanthorpe)",
    "franchise": "SEQ"
    },
    {
    "id": 47777,
    "label": "St Joseph's School (Tara)",
    "franchise": "NE"
    },
    {
    "id": 47722,
    "label": "St Joseph's Tobruk Memorial School",
    "franchise": "SEQ"
    },
    {
    "id": 47761,
    "label": "St Kevin's Catholic School (Benowa)",
    "franchise": "GCNR"
    },
    {
    "id": 47724,
    "label": "St Kevin's School (Geebung)",
    "franchise": "Brisbane"
    },
    {
    "id": 47815,
    "label": "St Kieran's Catholic School (Mount Isa)",
    "franchise": "CQ"
    },
    {
    "id": 47725,
    "label": "St Kieran's School (Brighton)",
    "franchise": "Brisbane"
    },
    {
    "id": 47855,
    "label": "St Laurence's College",
    "franchise": "SEQ"
    },
    {
    "id": 40604,
    "label": "St Lawrence State School",
    "franchise": "CQ"
    },
    {
    "id": 48008,
    "label": "St Luke's Anglican School (Kalkie)",
    "franchise": "CQ"
    },
    {
    "id": 47926,
    "label": "St Luke's Catholic Parish School (Capalaba)",
    "franchise": "SEQ"
    },
    {
    "id": 47911,
    "label": "St Margaret Mary's College (Hyde Park)",
    "franchise": "CQ"
    },
    {
    "id": 48000,
    "label": "St Margaret's Anglican Girls School (Ascot)",
    "franchise": "Brisbane"
    },
    {
    "id": 47778,
    "label": "St Maria Goretti School",
    "franchise": "SEQ"
    },
    {
    "id": 47726,
    "label": "St Mark's School",
    "franchise": "SEQ"
    },
    {
    "id": 47727,
    "label": "St Martin's School",
    "franchise": "SEQ"
    },
    {
    "id": 47652,
    "label": "St Mary MacKillop Primary School (Birkdale)",
    "franchise": "SEQ"
    },
    {
    "id": 47677,
    "label": "St Mary of the Cross School (Windsor)",
    "franchise": "Brisbane"
    },
    {
    "id": "43546",
    "label": "St Mary's Catholic College",
    "franchise": "GCNR"
    },
    {
    "id": 47892,
    "label": "St Mary's Catholic College (Kingaroy)",
    "franchise": "Brisbane"
    },
    {
    "id": 47919,
    "label": "St Mary's Catholic College (Woree)",
    "franchise": "CQ"
    },
    {
    "id": 47807,
    "label": "St Mary's Catholic Primary School (Bundaberg)",
    "franchise": "CQ"
    },
    {
    "id": 47809,
    "label": "St Mary's Catholic Primary School (Mackay South)",
    "franchise": "CQ"
    },
    {
    "id": 47808,
    "label": "St Mary's Catholic Primary School (North Rockhampton)",
    "franchise": "CQ"
    },
    {
    "id": 47828,
    "label": "St Mary's Catholic School (Bowen)",
    "franchise": "CQ"
    },
    {
    "id": 47881,
    "label": "St Mary's College (Ipswich)",
    "franchise": "SEQ"
    },
    {
    "id": 47891,
    "label": "St Mary's College (Maryborough)",
    "franchise": "CQ"
    },
    {
    "id": 47863,
    "label": "St Mary's College (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": "43604",
    "label": "St Mary's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47756,
    "label": "St Mary's Primary School (Maryborough)",
    "franchise": "CQ"
    },
    {
    "id": 47728,
    "label": "St Mary's Primary School (Woodend)",
    "franchise": "SEQ"
    },
    {
    "id": 47754,
    "label": "St Mary's School (Beaudesert)",
    "franchise": "SEQ"
    },
    {
    "id": 47783,
    "label": "St Mary's School (Charleville)",
    "franchise": "NE"
    },
    {
    "id": 47779,
    "label": "St Mary's School (Goondiwindi)",
    "franchise": "SEQ"
    },
    {
    "id": 47755,
    "label": "St Mary's School (Laidley)",
    "franchise": "SEQ"
    },
    {
    "id": 47781,
    "label": "St Mary's School (Warwick)",
    "franchise": "SEQ"
    },
    {
    "id": 47738,
    "label": "St Matthew's School",
    "franchise": "SEQ"
    },
    {
    "id": 47831,
    "label": "St Michael's Catholic School (Palm Island)",
    "franchise": "CQ"
    },
    {
    "id": 48049,
    "label": "St Michael's College (Caboolture)",
    "franchise": "Brisbane"
    },
    {
    "id": 47887,
    "label": "St Michael's College (Merrimac)",
    "franchise": "GCNR"
    },
    {
    "id": 47843,
    "label": "St Michael's School (Gordonvale)",
    "franchise": "CQ"
    },
    {
    "id": 47920,
    "label": "St Monica's College (Cairns)",
    "franchise": "CQ"
    },
    {
    "id": 47782,
    "label": "St Monica's School",
    "franchise": "SEQ"
    },
    {
    "id": 47670,
    "label": "St Oliver Plunkett School",
    "franchise": "SEQ"
    },
    {
    "id": 47811,
    "label": "St Patrick's Catholic Primary School (Bundaberg West)",
    "franchise": "CQ"
    },
    {
    "id": 47810,
    "label": "St Patrick's Catholic Primary School (Emerald)",
    "franchise": "CQ"
    },
    {
    "id": 47829,
    "label": "St Patrick's Catholic School (Winton)",
    "franchise": "CQ"
    },
    {
    "id": 47893,
    "label": "St Patrick's College (Gympie)",
    "franchise": "Brisbane"
    },
    {
    "id": 40684,
    "label": "St Patrick's College (Mackay)",
    "franchise": "CQ"
    },
    {
    "id": 47857,
    "label": "St Patrick's College (Shorncliffe)",
    "franchise": "Brisbane"
    },
    {
    "id": 47913,
    "label": "St Patrick's College Townsville",
    "franchise": "CQ"
    },
    {
    "id": 47757,
    "label": "St Patrick's Primary School (Gympie)",
    "franchise": "Brisbane"
    },
    {
    "id": 47786,
    "label": "St Patrick's Primary School (Mitchell)",
    "franchise": "NE"
    },
    {
    "id": 47758,
    "label": "St Patrick's Primary School (Nanango)",
    "franchise": "Brisbane"
    },
    {
    "id": 47784,
    "label": "St Patrick's School (Allora)",
    "franchise": "SEQ"
    },
    {
    "id": 47785,
    "label": "St Patrick's School (St George)",
    "franchise": "NE"
    },
    {
    "id": 47915,
    "label": "St Paul's Catholic Primary School (Gracemere)",
    "franchise": "CQ"
    },
    {
    "id": 48079,
    "label": "St Paul's Lutheran Primary School (Caboolture)",
    "franchise": "Brisbane"
    },
    {
    "id": 47999,
    "label": "St Paul's School (Bald Hills)",
    "franchise": "Brisbane"
    },
    {
    "id": 47729,
    "label": "St Paul's School (Woodridge)",
    "franchise": "SEQ"
    },
    {
    "id": 47730,
    "label": "St Peter Chanel Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 47883,
    "label": "St Peter Claver College",
    "franchise": "SEQ"
    },
    {
    "id": 47731,
    "label": "St Peter's Catholic Primary School (Caboolture)",
    "franchise": "Brisbane"
    },
    {
    "id": 47812,
    "label": "St Peter's Catholic Primary School (Rockhampton)",
    "franchise": "CQ"
    },
    {
    "id": 47830,
    "label": "St Peter's Catholic School (Halifax)",
    "franchise": "CQ"
    },
    {
    "id": 48025,
    "label": "St Peters Lutheran College (Indooroopilly)",
    "franchise": "Brisbane"
    },
    {
    "id": 48070,
    "label": "St Peters Lutheran College Springfield",
    "franchise": "SEQ"
    },
    {
    "id": 47733,
    "label": "St Peter's Primary School (Rochedale)",
    "franchise": "SEQ"
    },
    {
    "id": 40281,
    "label": "St Philomena School",
    "franchise": "SEQ"
    },
    {
    "id": 47734,
    "label": "St Pius' Primary School (Banyo)",
    "franchise": "Brisbane"
    },
    {
    "id": 47735,
    "label": "St Pius X School (Salisbury)",
    "franchise": "SEQ"
    },
    {
    "id": 47884,
    "label": "St Rita's College (Clayfield)",
    "franchise": "Brisbane"
    },
    {
    "id": 47923,
    "label": "St Rita's Primary School (Victoria Point)",
    "franchise": "SEQ"
    },
    {
    "id": 47844,
    "label": "St Rita's School (Babinda)",
    "franchise": "CQ"
    },
    {
    "id": 47845,
    "label": "St Rita's School (South Johnstone)",
    "franchise": "CQ"
    },
    {
    "id": 47898,
    "label": "St Saviour's College",
    "franchise": "SEQ"
    },
    {
    "id": 47787,
    "label": "St Saviour's Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47736,
    "label": "St Sebastian's Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47765,
    "label": "St Stephen's Catholic College (Mareeba)",
    "franchise": "CQ"
    },
    {
    "id": 47668,
    "label": "St Stephen's School (Algester)",
    "franchise": "SEQ"
    },
    {
    "id": 47788,
    "label": "St Stephen's School (Pittsworth)",
    "franchise": "SEQ"
    },
    {
    "id": 47667,
    "label": "St Teresa's Catholic College (Noosaville)",
    "franchise": "Brisbane"
    },
    {
    "id": 47914,
    "label": "St Teresa's College (Abergowrie)",
    "franchise": "CQ"
    },
    {
    "id": 47846,
    "label": "St Teresa's School (Ravenshoe)",
    "franchise": "CQ"
    },
    {
    "id": 47813,
    "label": "St Therese's Catholic Primary School (Monto)",
    "franchise": "CQ"
    },
    {
    "id": 47847,
    "label": "St Therese's School (Bentley Park)",
    "franchise": "CQ"
    },
    {
    "id": 47885,
    "label": "St Thomas More College (Sunnybank)",
    "franchise": "SEQ"
    },
    {
    "id": 47910,
    "label": "St Thomas More Primary School (Sunshine Beach)",
    "franchise": "Brisbane"
    },
    {
    "id": 47789,
    "label": "St Thomas More's Primary School (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47737,
    "label": "St Thomas' School (Camp Hill)",
    "franchise": "SEQ"
    },
    {
    "id": 47848,
    "label": "St Thomas' School (Mareeba)",
    "franchise": "CQ"
    },
    {
    "id": 47899,
    "label": "St Ursula's College (Toowoomba)",
    "franchise": "SEQ"
    },
    {
    "id": 47906,
    "label": "St Ursula's College (Yeppoon)",
    "franchise": "CQ"
    },
    {
    "id": 47760,
    "label": "St Vincent's Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 47739,
    "label": "St William's Primary School",
    "franchise": "Brisbane"
    },
    {
    "id": 46991,
    "label": "Stafford Heights State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46739,
    "label": "Stafford State School",
    "franchise": "Brisbane"
    },
    {
    "id": 40843,
    "label": "Staines Memorial College",
    "franchise": "SEQ"
    },
    {
    "id": 47457,
    "label": "Stanthorpe State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46499,
    "label": "Stanthorpe State School",
    "franchise": "SEQ"
    },
    {
    "id": 46496,
    "label": "Stanwell State School",
    "franchise": "CQ"
    },
    {
    "id": 47793,
    "label": "Star of the Sea Catholic Primary School (Gladstone)",
    "franchise": "CQ"
    },
    {
    "id": 47759,
    "label": "Star of the Sea Catholic School (Torquay)",
    "franchise": "CQ"
    },
    {
    "id": 47931,
    "label": "Star of the Sea Primary School (Cleveland)",
    "franchise": "SEQ"
    },
    {
    "id": 47763,
    "label": "Stella Maris School",
    "franchise": "Brisbane"
    },
    {
    "id": "42290",
    "label": "Stokers Siding Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46951,
    "label": "Stonehenge State School",
    "franchise": "NE"
    },
    {
    "id": "42292",
    "label": "Stratheden Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47079,
    "label": "Strathpine State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47325,
    "label": "Strathpine West State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47634,
    "label": "Stretton State College",
    "franchise": "SEQ"
    },
    {
    "id": 47732,
    "label": "Sts Peter and Paul's School",
    "franchise": "SEQ"
    },
    {
    "id": 47877,
    "label": "Stuartholme School",
    "franchise": "Brisbane"
    },
    {
    "id": "43771",
    "label": "Summerland Christian College",
    "franchise": "GCNR"
    },
    {
    "id": 46822,
    "label": "Sunbury State School",
    "franchise": "CQ"
    },
    {
    "id": 48100,
    "label": "Suncoast Christian College",
    "franchise": "Brisbane"
    },
    {
    "id": 47307,
    "label": "Sunnybank Hills State School",
    "franchise": "SEQ"
    },
    {
    "id": 47615,
    "label": "Sunnybank Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47467,
    "label": "Sunnybank State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46446,
    "label": "Sunnybank State School",
    "franchise": "SEQ"
    },
    {
    "id": 46968,
    "label": "Sunset State School",
    "franchise": "CQ"
    },
    {
    "id": 47493,
    "label": "Sunshine Beach State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47330,
    "label": "Sunshine Beach State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47948,
    "label": "Sunshine Coast Grammar School",
    "franchise": "Brisbane"
    },
    {
    "id": 46505,
    "label": "Surat State School",
    "franchise": "NE"
    },
    {
    "id": 47096,
    "label": "Surfers Paradise State School",
    "franchise": "GCNR"
    },
    {
    "id": 47197,
    "label": "Swayneville State School",
    "franchise": "CQ"
    },
    {
    "id": 47017,
    "label": "Taabinga State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42295",
    "label": "Tabulam Public School",
    "franchise": "GCNR"
    },
    {
    "id": 40788,
    "label": "Tagai State College",
    "franchise": "CQ"
    },
    {
    "id": 47021,
    "label": "Taigum State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46718,
    "label": "Talara Primary College",
    "franchise": "Brisbane"
    },
    {
    "id": 46554,
    "label": "Tallebudgera State School",
    "franchise": "GCNR"
    },
    {
    "id": 47203,
    "label": "Talwood State School",
    "franchise": "SEQ"
    },
    {
    "id": 46511,
    "label": "Tambo State School",
    "franchise": "NE"
    },
    {
    "id": 47937,
    "label": "Tamborine Mountain College",
    "franchise": "SEQ"
    },
    {
    "id": 47569,
    "label": "Tamborine Mountain State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46941,
    "label": "Tamborine Mountain State School",
    "franchise": "SEQ"
    },
    {
    "id": 47082,
    "label": "Tamrookum State School",
    "franchise": "SEQ"
    },
    {
    "id": 47141,
    "label": "Tanduringie State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46576,
    "label": "Tannum Sands State High School",
    "franchise": "CQ"
    },
    {
    "id": 47334,
    "label": "Tannum Sands State School",
    "franchise": "CQ"
    },
    {
    "id": 47116,
    "label": "Tara Shire State College",
    "franchise": "NE"
    },
    {
    "id": 46596,
    "label": "Tarampa State School",
    "franchise": "SEQ"
    },
    {
    "id": 47399,
    "label": "Taranganba State School",
    "franchise": "CQ"
    },
    {
    "id": 46476,
    "label": "Taroom State School",
    "franchise": "CQ"
    },
    {
    "id": 40789,
    "label": "Tec-NQ",
    "franchise": "CQ"
    },
    {
    "id": 46743,
    "label": "Teelba State School",
    "franchise": "NE"
    },
    {
    "id": 46477,
    "label": "Tent Hill Lower State School",
    "franchise": "SEQ"
    },
    {
    "id": "42299",
    "label": "Terranora Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42354",
    "label": "Teven-Tintenbar Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46539,
    "label": "Tewantin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46700,
    "label": "Texas P-10 State School",
    "franchise": "SEQ"
    },
    {
    "id": 47190,
    "label": "Thabeban State School",
    "franchise": "CQ"
    },
    {
    "id": 47090,
    "label": "Thallon State School",
    "franchise": "NE"
    },
    {
    "id": 47277,
    "label": "Thangool State School",
    "franchise": "CQ"
    },
    {
    "id": 46688,
    "label": "Thargomindah State School",
    "franchise": "NE"
    },
    {
    "id": 52896,
    "label": "The BUSY School",
    "franchise": "CQ"
    },
    {
    "id": 47916,
    "label": "The Cathedral College (Rockhampton)",
    "franchise": "CQ"
    },
    {
    "id": 48004,
    "label": "The Cathedral School of St Anne and St James (Mundingburra)",
    "franchise": "CQ"
    },
    {
    "id": 46992,
    "label": "The Caves State School",
    "franchise": "CQ"
    },
    {
    "id": 47655,
    "label": "The Centre Education Programme",
    "franchise": "SEQ"
    },
    {
    "id": "42171",
    "label": "The Channon Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47447,
    "label": "The Gap State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47099,
    "label": "The Gap State School",
    "franchise": "Brisbane"
    },
    {
    "id": 48098,
    "label": "The Glenleighden School",
    "franchise": "Brisbane"
    },
    {
    "id": 48010,
    "label": "The Glennie School",
    "franchise": "SEQ"
    },
    {
    "id": 47118,
    "label": "The Gums State School",
    "franchise": "NE"
    },
    {
    "id": 47061,
    "label": "The Hall State School",
    "franchise": "CQ"
    },
    {
    "id": 48013,
    "label": "The Kooralbyn International School",
    "franchise": "SEQ"
    },
    {
    "id": 47990,
    "label": "The Lakes College",
    "franchise": "Brisbane"
    },
    {
    "id": "42276",
    "label": "The Pocket Public School",
    "franchise": "GCNR"
    },
    {
    "id": 52667,
    "label": "The Rainforest School",
    "franchise": "CQ"
    },
    {
    "id": "42281",
    "label": "The Risk Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47939,
    "label": "The River School",
    "franchise": "Brisbane"
    },
    {
    "id": "42398",
    "label": "The Rivers Secondary College, Kadina High Campus",
    "franchise": "GCNR"
    },
    {
    "id": "42375",
    "label": "The Rivers Secondary College, Lismore High Campus",
    "franchise": "GCNR"
    },
    {
    "id": "42384",
    "label": "The Rivers Secondary College, Richmond River High Campus",
    "franchise": "GCNR"
    },
    {
    "id": 48038,
    "label": "The Rockhampton Grammar School (Primary and Secondary)",
    "franchise": "CQ"
    },
    {
    "id": 48029,
    "label": "The School of Total Education",
    "franchise": "SEQ"
    },
    {
    "id": 48046,
    "label": "The SCOTS PGC College",
    "franchise": "SEQ"
    },
    {
    "id": "52680",
    "label": "The Small School",
    "franchise": "GCNR"
    },
    {
    "id": 48003,
    "label": "The Southport School",
    "franchise": "GCNR"
    },
    {
    "id": 40961,
    "label": "The Springfield Anglican College",
    "franchise": "SEQ"
    },
    {
    "id": 47236,
    "label": "The Summit State School",
    "franchise": "SEQ"
    },
    {
    "id": 52452,
    "label": "The Sycamore School",
    "franchise": "SEQ"
    },
    {
    "id": 53050,
    "label": "The Village School Gold Coast",
    "franchise": "GCNR"
    },
    {
    "id": 46608,
    "label": "The Willows State School",
    "franchise": "CQ"
    },
    {
    "id": 46793,
    "label": "Theebine State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46843,
    "label": "Theodore State School",
    "franchise": "CQ"
    },
    {
    "id": 47074,
    "label": "Thornlands State School",
    "franchise": "SEQ"
    },
    {
    "id": 46588,
    "label": "Thornton State School",
    "franchise": "SEQ"
    },
    {
    "id": 46901,
    "label": "Thulimbah State School",
    "franchise": "SEQ"
    },
    {
    "id": 47550,
    "label": "Thuringowa State High School",
    "franchise": "CQ"
    },
    {
    "id": 46478,
    "label": "Tiaro State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47339,
    "label": "Tieri State School",
    "franchise": "CQ"
    },
    {
    "id": 46792,
    "label": "Tin Can Bay State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46563,
    "label": "Tinana State School",
    "franchise": "CQ"
    },
    {
    "id": 46494,
    "label": "Tingalpa State School",
    "franchise": "SEQ"
    },
    {
    "id": 46918,
    "label": "Tingoora State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46570,
    "label": "Tivoli State School",
    "franchise": "SEQ"
    },
    {
    "id": 46824,
    "label": "Tolga State School",
    "franchise": "CQ"
    },
    {
    "id": 52402,
    "label": "Tom Quinn Community Centre Alternative School",
    "franchise": "CQ"
    },
    {
    "id": 47262,
    "label": "Toobanna State School",
    "franchise": "CQ"
    },
    {
    "id": 47954,
    "label": "Toogoolawa School",
    "franchise": "GCNR"
    },
    {
    "id": 47557,
    "label": "Toogoolawah State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47011,
    "label": "Toogoolawah State School",
    "franchise": "SEQ"
    },
    {
    "id": 47531,
    "label": "Toolooa State High School",
    "franchise": "CQ"
    },
    {
    "id": 46402,
    "label": "Toowong State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47994,
    "label": "Toowoomba Anglican School",
    "franchise": "SEQ"
    },
    {
    "id": 48054,
    "label": "Toowoomba Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 46747,
    "label": "Toowoomba East State School",
    "franchise": "SEQ"
    },
    {
    "id": 48037,
    "label": "Toowoomba Grammar School",
    "franchise": "SEQ"
    },
    {
    "id": 46480,
    "label": "Toowoomba North State School",
    "franchise": "SEQ"
    },
    {
    "id": 47417,
    "label": "Toowoomba State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47598,
    "label": "Toowoomba West Special School",
    "franchise": "SEQ"
    },
    {
    "id": 46762,
    "label": "Torbanlea State School",
    "franchise": "CQ"
    },
    {
    "id": 46957,
    "label": "Torquay State School",
    "franchise": "CQ"
    },
    {
    "id": 46483,
    "label": "Townsville Central State School",
    "franchise": "CQ"
    },
    {
    "id": 52249,
    "label": "Townsville Christian College",
    "franchise": "CQ"
    },
    {
    "id": 47577,
    "label": "Townsville Community Learning Centre - A State Special School",
    "franchise": "CQ"
    },
    {
    "id": 47929,
    "label": "Townsville Flexible Learning Centre",
    "franchise": "CQ"
    },
    {
    "id": 47981,
    "label": "Townsville Grammar School",
    "franchise": "CQ"
    },
    {
    "id": 46621,
    "label": "Townsville South State School",
    "franchise": "CQ"
    },
    {
    "id": 47485,
    "label": "Townsville State High School",
    "franchise": "CQ"
    },
    {
    "id": 46746,
    "label": "Townsville West State School",
    "franchise": "CQ"
    },
    {
    "id": 47063,
    "label": "Townview State School",
    "franchise": "CQ"
    },
    {
    "id": 47023,
    "label": "Trebonne State School",
    "franchise": "CQ"
    },
    {
    "id": "42302",
    "label": "Tregeagle Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47997,
    "label": "Trinity Anglican School",
    "franchise": "CQ"
    },
    {
    "id": 47451,
    "label": "Trinity Bay State High School",
    "franchise": "CQ"
    },
    {
    "id": "43654",
    "label": "Trinity Catholic College Lismore",
    "franchise": "GCNR"
    },
    {
    "id": "52077",
    "label": "Trinity Catholic College Lismore, St Joseph's Campus",
    "franchise": "GCNR"
    },
    {
    "id": 47880,
    "label": "Trinity College (Beenleigh)",
    "franchise": "SEQ"
    },
    {
    "id": 48050,
    "label": "Trinity College (Gladstone)",
    "franchise": "CQ"
    },
    {
    "id": 47977,
    "label": "Trinity Lutheran College (Ashmore)",
    "franchise": "GCNR"
    },
    {
    "id": 47538,
    "label": "Tropical North Learning Academy - Smithfield State High School",
    "franchise": "CQ"
    },
    {
    "id": 47311,
    "label": "Tropical North Learning Academy - Trinity Beach State School",
    "franchise": "CQ"
    },
    {
    "id": 52690,
    "label": "Tropical North Steiner School",
    "franchise": "CQ"
    },
    {
    "id": 47565,
    "label": "Tullawong State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 47401,
    "label": "Tullawong State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47488,
    "label": "Tully State High School",
    "franchise": "CQ"
    },
    {
    "id": 46988,
    "label": "Tully State School",
    "franchise": "CQ"
    },
    {
    "id": "42304",
    "label": "Tumbulgum Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42305",
    "label": "Tuntable Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": "43818",
    "label": "Tuntable Falls Community School",
    "franchise": "GCNR"
    },
    {
    "id": "42306",
    "label": "Tweed Heads Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42337",
    "label": "Tweed Heads South Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42391",
    "label": "Tweed River High School",
    "franchise": "GCNR"
    },
    {
    "id": "43801",
    "label": "Tweed Valley Adventist College",
    "franchise": "GCNR"
    },
    {
    "id": 46659,
    "label": "Two Mile State School",
    "franchise": "Brisbane"
    },
    {
    "id": "42307",
    "label": "Tyalgum Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42308",
    "label": "Uki Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47299,
    "label": "Undurba State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47921,
    "label": "Unity College",
    "franchise": "Brisbane"
    },
    {
    "id": 47163,
    "label": "Upper Brookfield State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47575,
    "label": "Upper Coomera State College",
    "franchise": "GCNR"
    },
    {
    "id": "42328",
    "label": "Upper Coopers Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46495,
    "label": "Upper Mount Gravatt State School",
    "franchise": "SEQ"
    },
    {
    "id": 47257,
    "label": "Urandangi State School",
    "franchise": "NE"
    },
    {
    "id": 47176,
    "label": "Urangan Point State School",
    "franchise": "CQ"
    },
    {
    "id": 47495,
    "label": "Urangan State High School",
    "franchise": "CQ"
    },
    {
    "id": "42311",
    "label": "Urbenville Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46913,
    "label": "Vale View State School",
    "franchise": "SEQ"
    },
    {
    "id": 46685,
    "label": "Valkyrie State School",
    "franchise": "CQ"
    },
    {
    "id": 47259,
    "label": "Varsity College",
    "franchise": "GCNR"
    },
    {
    "id": 46922,
    "label": "Veresdale Scrub State School",
    "franchise": "SEQ"
    },
    {
    "id": 47282,
    "label": "Victoria Park State School",
    "franchise": "CQ"
    },
    {
    "id": 46823,
    "label": "Victoria Plantation State School",
    "franchise": "CQ"
    },
    {
    "id": 46578,
    "label": "Victoria Point State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47166,
    "label": "Victoria Point State School",
    "franchise": "SEQ"
    },
    {
    "id": 48101,
    "label": "Victory College",
    "franchise": "Brisbane"
    },
    {
    "id": 47346,
    "label": "Vienna Woods State School",
    "franchise": "SEQ"
    },
    {
    "id": 47858,
    "label": "Villanova College",
    "franchise": "SEQ"
    },
    {
    "id": 46959,
    "label": "Vincent State School",
    "franchise": "CQ"
    },
    {
    "id": 47223,
    "label": "Virginia State School",
    "franchise": "Brisbane"
    },
    {
    "id": "43826",
    "label": "Vistara Primary School",
    "franchise": "GCNR"
    },
    {
    "id": 48040,
    "label": "Wadja Wadja High School",
    "franchise": "CQ"
    },
    {
    "id": 46881,
    "label": "Walkamin State School",
    "franchise": "CQ"
    },
    {
    "id": 46651,
    "label": "Walkerston State School",
    "franchise": "CQ"
    },
    {
    "id": 46771,
    "label": "Walkervale State School",
    "franchise": "CQ"
    },
    {
    "id": 46715,
    "label": "Wallangarra State School",
    "franchise": "SEQ"
    },
    {
    "id": 47059,
    "label": "Wallaville State School",
    "franchise": "CQ"
    },
    {
    "id": 46606,
    "label": "Walloon State School",
    "franchise": "SEQ"
    },
    {
    "id": 46540,
    "label": "Wallumbilla State School",
    "franchise": "NE"
    },
    {
    "id": 47241,
    "label": "Wamuran State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47097,
    "label": "Wandoan State School P-10",
    "franchise": "NE"
    },
    {
    "id": 47380,
    "label": "Waraburra State School",
    "franchise": "CQ"
    },
    {
    "id": "42313",
    "label": "Wardell Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46673,
    "label": "Warra State School",
    "franchise": "NE"
    },
    {
    "id": 47303,
    "label": "Warrigal Road State School",
    "franchise": "SEQ"
    },
    {
    "id": 47072,
    "label": "Warrill View State School",
    "franchise": "SEQ"
    },
    {
    "id": 47043,
    "label": "Wartburg State School",
    "franchise": "CQ"
    },
    {
    "id": 46486,
    "label": "Warwick Central State School",
    "franchise": "SEQ"
    },
    {
    "id": 50594,
    "label": "Warwick Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 46571,
    "label": "Warwick East State School",
    "franchise": "SEQ"
    },
    {
    "id": 47418,
    "label": "Warwick State High School",
    "franchise": "SEQ"
    },
    {
    "id": 47041,
    "label": "Warwick West State School",
    "franchise": "SEQ"
    },
    {
    "id": 46497,
    "label": "Waterford State School",
    "franchise": "SEQ"
    },
    {
    "id": 47283,
    "label": "Waterford West State School",
    "franchise": "SEQ"
    },
    {
    "id": 46797,
    "label": "Watson Road State School",
    "franchise": "SEQ"
    },
    {
    "id": 47040,
    "label": "Wavell Heights State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47442,
    "label": "Wavell State High School",
    "franchise": "Brisbane"
    },
    {
    "id": 46680,
    "label": "Weir State School",
    "franchise": "CQ"
    },
    {
    "id": 46916,
    "label": "Wellcamp State School",
    "franchise": "SEQ"
    },
    {
    "id": 47278,
    "label": "Wellers Hill State School",
    "franchise": "SEQ"
    },
    {
    "id": 47556,
    "label": "Wellington Point State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46597,
    "label": "Wellington Point State School",
    "franchise": "SEQ"
    },
    {
    "id": 46560,
    "label": "West End State School",
    "franchise": "SEQ"
    },
    {
    "id": 48006,
    "label": "West Moreton Anglican College",
    "franchise": "SEQ"
    },
    {
    "id": 40765,
    "label": "Western Cape College",
    "franchise": "CQ"
    },
    {
    "id": 47590,
    "label": "Western Suburbs State Special School",
    "franchise": "SEQ"
    },
    {
    "id": 47078,
    "label": "Westmar State School",
    "franchise": "NE"
    },
    {
    "id": 48053,
    "label": "Westside Christian College",
    "franchise": "SEQ"
    },
    {
    "id": 46488,
    "label": "Westwood State School",
    "franchise": "CQ"
    },
    {
    "id": 47131,
    "label": "Wheatlands State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47038,
    "label": "Wheatvale State School",
    "franchise": "SEQ"
    },
    {
    "id": "42282",
    "label": "Whian Whian Public School",
    "franchise": "GCNR"
    },
    {
    "id": 47386,
    "label": "White Rock State School",
    "franchise": "CQ"
    },
    {
    "id": 47579,
    "label": "Whites Hill State College",
    "franchise": "SEQ"
    },
    {
    "id": 47388,
    "label": "Whitfield State School",
    "franchise": "CQ"
    },
    {
    "id": 48110,
    "label": "Whitsunday Anglican School",
    "franchise": "CQ"
    },
    {
    "id": 47944,
    "label": "Whitsunday Christian College",
    "franchise": "CQ"
    },
    {
    "id": "42315",
    "label": "Wiangaree Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46839,
    "label": "Widgee State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47381,
    "label": "William Duncan State School",
    "franchise": "GCNR"
    },
    {
    "id": 47563,
    "label": "William Ross State High School",
    "franchise": "CQ"
    },
    {
    "id": "42367",
    "label": "Wilson Park School",
    "franchise": "GCNR"
    },
    {
    "id": "42317",
    "label": "Wilsons Creek Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46724,
    "label": "Wilsonton State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46679,
    "label": "Wilsonton State School",
    "franchise": "SEQ"
    },
    {
    "id": 47157,
    "label": "Wilston State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47397,
    "label": "Windaroo State School",
    "franchise": "SEQ"
    },
    {
    "id": 47566,
    "label": "Windaroo Valley State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46611,
    "label": "Windera State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46717,
    "label": "Windorah State School",
    "franchise": "NE"
    },
    {
    "id": 46401,
    "label": "Windsor State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46733,
    "label": "Winton State School",
    "franchise": "CQ"
    },
    {
    "id": 50499,
    "label": "Wisdom College",
    "franchise": "SEQ"
    },
    {
    "id": 46825,
    "label": "Wishart State School",
    "franchise": "SEQ"
    },
    {
    "id": 47343,
    "label": "Withcott State School",
    "franchise": "SEQ"
    },
    {
    "id": "42318",
    "label": "Wollongbar Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42408",
    "label": "Wollumbin High School",
    "franchise": "GCNR"
    },
    {
    "id": 46929,
    "label": "Wolvi State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47008,
    "label": "Wondai State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46692,
    "label": "Wondall Heights State School",
    "franchise": "SEQ"
    },
    {
    "id": 47202,
    "label": "Wonga Beach State School",
    "franchise": "CQ"
    },
    {
    "id": "42319",
    "label": "Woodburn Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46713,
    "label": "Woodcrest State College",
    "franchise": "SEQ"
    },
    {
    "id": "42320",
    "label": "Woodenbong Central School",
    "franchise": "GCNR"
    },
    {
    "id": 46664,
    "label": "Woodford State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46489,
    "label": "Woodhill State School",
    "franchise": "SEQ"
    },
    {
    "id": 50366,
    "label": "WoodLinks State School",
    "franchise": "SEQ"
    },
    {
    "id": 46831,
    "label": "Woodridge North State School",
    "franchise": "SEQ"
    },
    {
    "id": 47509,
    "label": "Woodridge State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46857,
    "label": "Woodridge State School",
    "franchise": "SEQ"
    },
    {
    "id": 46817,
    "label": "Woodstock State School",
    "franchise": "CQ"
    },
    {
    "id": 47605,
    "label": "Woody Point Special School",
    "franchise": "Brisbane"
    },
    {
    "id": 47120,
    "label": "Woolooga State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47144,
    "label": "Wooloowin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46732,
    "label": "Woombye State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46649,
    "label": "Woongarra State School",
    "franchise": "CQ"
    },
    {
    "id": 46425,
    "label": "Woongoolba State School",
    "franchise": "GCNR"
    },
    {
    "id": 46819,
    "label": "Woorabinda State School",
    "franchise": "CQ"
    },
    {
    "id": 46958,
    "label": "Wooroolin State School",
    "franchise": "Brisbane"
    },
    {
    "id": 47541,
    "label": "Woree State High School",
    "franchise": "CQ"
    },
    {
    "id": 47317,
    "label": "Woree State School",
    "franchise": "CQ"
    },
    {
    "id": 47400,
    "label": "Worongary State School",
    "franchise": "GCNR"
    },
    {
    "id": 46944,
    "label": "Wowan State School",
    "franchise": "CQ"
    },
    {
    "id": 46779,
    "label": "Wulguru State School",
    "franchise": "CQ"
    },
    {
    "id": 46900,
    "label": "Wyandra State School",
    "franchise": "NE"
    },
    {
    "id": 47422,
    "label": "Wynnum State High School",
    "franchise": "SEQ"
    },
    {
    "id": 50367,
    "label": "Wynnum State School",
    "franchise": "SEQ"
    },
    {
    "id": 47260,
    "label": "Wynnum West State School",
    "franchise": "SEQ"
    },
    {
    "id": "42322",
    "label": "Wyrallah Public School",
    "franchise": "GCNR"
    },
    {
    "id": "42330",
    "label": "Wyrallah Road Public School",
    "franchise": "GCNR"
    },
    {
    "id": 46764,
    "label": "Wyreema State School",
    "franchise": "SEQ"
    },
    {
    "id": 47665,
    "label": "Xavier Catholic College (Hervey Bay)",
    "franchise": "CQ"
    },
    {
    "id": "43539",
    "label": "Xavier Catholic College Ballina",
    "franchise": "GCNR"
    },
    {
    "id": 47209,
    "label": "Yandaran State School",
    "franchise": "CQ"
    },
    {
    "id": 46742,
    "label": "Yandina State School",
    "franchise": "Brisbane"
    },
    {
    "id": 46512,
    "label": "Yangan State School",
    "franchise": "SEQ"
    },
    {
    "id": 47100,
    "label": "Yarrabah State School",
    "franchise": "CQ"
    },
    {
    "id": 52611,
    "label": "Yarrabilba State School",
    "franchise": "SEQ"
    },
    {
    "id": 52805,
    "label": "Yarrabilba State Secondary College",
    "franchise": "SEQ"
    },
    {
    "id": 46970,
    "label": "Yarraman State School",
    "franchise": "Brisbane"
    },
    {
    "id": 51490,
    "label": "Yarranlea Primary School",
    "franchise": "SEQ"
    },
    {
    "id": 47252,
    "label": "Yarrilee State School",
    "franchise": "CQ"
    },
    {
    "id": 47018,
    "label": "Yarwun State School",
    "franchise": "CQ"
    },
    {
    "id": 47111,
    "label": "Yelarbon State School",
    "franchise": "SEQ"
    },
    {
    "id": 47506,
    "label": "Yeppoon State High School",
    "franchise": "CQ"
    },
    {
    "id": 46703,
    "label": "Yeppoon State School",
    "franchise": "CQ"
    },
    {
    "id": 47448,
    "label": "Yeronga State High School",
    "franchise": "SEQ"
    },
    {
    "id": 46399,
    "label": "Yeronga State School",
    "franchise": "SEQ"
    },
    {
    "id": 52874,
    "label": "YMCA Vocational School - Brisbane South",
    "franchise": "SEQ"
    },
    {
    "id": 52872,
    "label": "YMCA Vocational School - Ipswich",
    "franchise": "SEQ"
    },
    {
    "id": 52867,
    "label": "YMCA Vocational School - Logan",
    "franchise": "SEQ"
    },
    {
    "id": 52870,
    "label": "YMCA Vocational School - Moreton Bay",
    "franchise": "Brisbane"
    },
    {
    "id": 52869,
    "label": "YMCA Vocational School - Redlands",
    "franchise": "SEQ"
    },
    {
    "id": 46878,
    "label": "Yorkeys Knob State School",
    "franchise": "CQ"
    },
    {
    "id": 51531,
    "label": "YOS Lawnton",
    "franchise": "Brisbane"
    },
    {
    "id": 47364,
    "label": "Yugumbir State School",
    "franchise": "SEQ"
    },
    {
    "id": 46666,
    "label": "Yuleba State School",
    "franchise": "NE"
    },
    {
    "id": 46984,
    "label": "Yungaburra State School",
    "franchise": "CQ"
    },
    {
    "id": 46599,
    "label": "Zillmere State School",
    "franchise": "Brisbane"
    },
    {
        "id": "60000",
        "label": "Scenic Shores State School",
        "franchise": "SEQ"
    },
    {
        "id": "60001",
        "label": "Bellbird Park State School",
        "franchise": "SEQ"
    },
    {
        "id": "60002",
        "label": "Everleigh State school",
        "franchise": "SEQ"
    },
    {
        "id": "60003",
        "label": "Woogaroo Creek State School",
        "franchise": "SEQ"
    },
    {
        "id": "60004",
        "label": "South Rock State School",
        "franchise": "SEQ"
    },
    {
        "id": "60005",
        "label": "Ripley Central State school",
        "franchise": "SEQ"
    },
    {
        "id": "60006",
        "label": "Palmview State Secondary College ",
        "franchise": "Brisbane"
    },
    {
        "id": "60007",
        "label": "Holy Spirit College, Fitzgibbon",
        "franchise": "Brisbane"
    },
    {
        "id": "60008",
        "label": "Grace Lutheran College (Rothwell)",
        "franchise": "Brisbane"
    },
    {
        "id": "60009",
        "label": "Grace Lutheran College (Caboolture)",
        "franchise": "Brisbane"
    },
    {
        "id": "60010",
        "label": "Grace Early Learning Centre",
        "franchise": "Brisbane"
    },
    {
        "id": "60011",
        "label": "Nirimba State Primary School",
        "franchise": "Brisbane"
    },
    {
        "id": "60012",
        "label": "Norfolk Island Central School",
        "franchise": "Brisbane"
    },
    {
        "id": "60013",
        "label": "Saint Finbarr's School, Ashgrove",
        "franchise": "Brisbane"
    },
    {
        "id": "60014",
        "label": "St Michaels Early Learning Centre",
        "franchise": "Brisbane"
    },
    {
        "id": "60015",
        "label": "Star of the Sea School (Merrimac)",
        "franchise": "GCNR"
    },
    {
        "id": "60016",
        "label": "King's Christian College (Logan Village)",
        "franchise": "GCNR"
    },
    {
        "id": "60017",
        "label": "King's Christian College (Pimpama)",
        "franchise": "GCNR"
    },
    {
        "id": "60018",
        "label": "Mastery Schools Australia (Southport)",
        "franchise": "GCNR"
    },
    {
        "id": "60019",
        "label": "Mastery Schools Australia (Coolangatta)",
        "franchise": "GCNR"
    },
    {
        "id": "60020",
        "label": "Master Schools Australia (Springfield)",
        "franchise": "GCNR"
    },
    {
        "id": "60021",
        "label": "Silkwood School (Robina)",
        "franchise": "GCNR"
    },
    {
        "id": "60022",
        "label": "St Andrews Lutheran Early Learning Centre",
        "franchise": "GCNR"
    },
    {
        "id": "60023",
        "label": "Tree of Life Childcare (Mudgeeraba)",
        "franchise": "GCNR"
    },
    {
        "id": "60024",
        "label": "Trinity Lutheran College Early Learning Centre (Ashmore)",
        "franchise": "GCNR"
    },
    {
        "id": "60025",
        "label": "Coomera State Special School",
        "franchise": "GCNR"
    }
]

export const BRISBANE_URL = "https://www.cognitoforms.com/MSPPhotography3/BrisbaneLateOrders";
export const GCNR_URL = "https://www.cognitoforms.com/MSPPhotography3/GCNRLateOrders";
export const NE_URL = "https://www.cognitoforms.com/MSPPhotography3/NELateOrders";
export const SEQ_URL = "https://www.cognitoforms.com/MSPPhotography3/SEQLateOrders";
export const CQ_URL = "https://www.cognitoforms.com/MSPPhotography3/CQLateOrders";

