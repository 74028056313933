import React, { useState } from "react";
import styles from './index.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { BRISBANE_URL, CQ_URL, GCNR_URL, NE_URL, SCHOOL_LIST, SEQ_URL } from '../constants';
import swal from 'sweetalert';
import { Col, Container, Row } from "react-bootstrap";

function LandingPage() {
    const [school, setSchool] = useState('')
    const [franchise, setFranchise] = useState('')

    const handleContinue = (e) => {
        e.preventDefault();

        console.log(school);
        console.log(franchise);
        if(!school) {
            swal({
                title: "Error",
                text: "School name doesn't exist.",
                icon: "error",
            });
            return false;
        }

        if(!franchise) {
            swal({
                title: "Error",
                text: "The school doesn't have a franchise.",
                icon: "error",
            });
            return false;
        }

        let franchiseVal = franchise.toLocaleLowerCase();
        let schoolEndpoint = `?entry={"School":"${school}"}`;

        switch (franchiseVal) {
            case "brisbane":
                window.location.href = BRISBANE_URL+schoolEndpoint;
                break;
            case "gcnr":    
                window.location.href = GCNR_URL+schoolEndpoint;
                break;
            case "ne":    
                window.location.href = NE_URL+schoolEndpoint;
                break;
            case "seq":    
                window.location.href = SEQ_URL+schoolEndpoint;
                break;
            case "cq":    
                window.location.href = CQ_URL+schoolEndpoint;
                break;
            default:
                break;
        }
      
    }

    return (
        <div className={styles.container}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <img src="./msp-photog-logo.png" className="img-fluid" alt="Logo" />
                        <p className={"mt-5 mb-3 " + styles.title}>Enter your school</p>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={SCHOOL_LIST}
                            isOptionEqualToValue={(option, value) => value === undefined || value === "" || option.label === value }
                            value={school} 
                            onChange ={(event, data) => {
                                setSchool(data.label)
                                setFranchise(data.franchise)
                            }}
                            renderInput={(params) => <TextField {...params} label="School" />}
                        />
                        <Button type="submit" variant="contained" className="mt-5" onClick={handleContinue}>Continue</Button> 
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LandingPage;
